import React from "react";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { Button } from "react-bootstrap";
import { parseISO, format, getDay } from "date-fns";
import { es } from "date-fns/locale";

const DownloadExcel = ({ excelData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = async () => {
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    // Agregar texto adicional cada 5 filas y en 5 columnas consecutivas
    const additionalText = "Texto adicional";
    const additionalColumns = 5;
    const additionalRow1 = Array(additionalColumns).fill(additionalText);

    for (let i = 0; i < excelData?.length; i++) {
      //excelData[i].date
      const fecha = excelData[i].date;
      const fechaISO = parseISO(fecha);
      const diaSemana = format(fechaISO, "eeee", { locale: es });
      const additionalRow1 = Array(additionalColumns).fill(""); // Crea un array vacío
      additionalRow1[0] = ""; // Asigna texto a la primera columna
      additionalRow1[1] = ""; // Asigna texto a la segunda columna
      additionalRow1[2] = ""; // Asigna texto a la tercera columna
      additionalRow1[3] = "Día:"; // Asigna texto a la cuarta columna
      additionalRow1[4] = diaSemana;
      additionalRow1[5] = format(fechaISO, "dd/MM"); // Asigna texto a la quinta columna
      XLSX.utils.sheet_add_aoa(worksheet, [additionalRow1], {
        origin: -1,
        skipHeader: true,
      });

      for (let j = 0; j < excelData[i].grades.length; j++) {
        //excelData[i].grades[j].grade
        for (let k = 0; k < excelData[i].grades[j].commissions.length; k++) {
          if(excelData[i].grades[j].commissions[k].data.length){
            //excelData[i].grades[j].commissions[k].commission
            const additionalRow1 = Array(additionalColumns).fill(""); // Crea un array vacío
            additionalRow1[0] =
              excelData[i].grades[j].grade === "0"
                ? "Jardín"
                : excelData[i].grades[j].grade + "º Grado"; // Asigna texto a la primera columna
            additionalRow1[1] = "División:"; // Asigna texto a la segunda columna
            additionalRow1[2] = excelData[i].grades[j].commissions[k].description ; // Asigna texto a la tercera columna
            XLSX.utils.sheet_add_aoa(worksheet, [additionalRow1], {
              origin: -1,
              skipHeader: true,
            });
  
            //<-----------------fila en blanco----------------->
            const emptyRow = [null, null, null];
            XLSX.utils.sheet_add_aoa(worksheet, [emptyRow], { origin: -1 });
            //<-----------------Fin fila en blanco----------------->
  
            const additionalRow2 = Array(additionalColumns).fill(""); // Crea un array vacío
            additionalRow2[0] = "Nombre Alumno";
            additionalRow2[1] = "Menú Semanal";
            additionalRow2[2] = "Vianda";
            XLSX.utils.sheet_add_aoa(worksheet, [additionalRow2], {
              origin: -1,
              skipHeader: true,
            });
            for (
              let l = 0;
              l < excelData[i].grades[j].commissions[k].data.length;
              l++
            ) {
              if(excelData[i].grades[j].commissions[k].data[l].vianda || excelData[i].grades[j].commissions[k].data[l].menu){
                const additionalRow3 = Array(additionalColumns).fill(""); // Crea un array vacío
                additionalRow3[0] =
                  excelData[i].grades[j].commissions[k].data[l].nombre;
                additionalRow3[1] =
                  excelData[i].grades[j].commissions[k].data[l].menu;
                additionalRow3[2] =
                  excelData[i].grades[j].commissions[k].data[l].vianda;
                XLSX.utils.sheet_add_aoa(worksheet, [additionalRow3], {
                  origin: -1,
                  skipHeader: true,
                }); 
              }
            }
            //<-----------------fila en blanco----------------->
  
            XLSX.utils.sheet_add_aoa(worksheet, [emptyRow], { origin: -1 });
            //<-----------------Fin fila en blanco----------------->
            //<-----------------fila en blanco----------------->
  
            XLSX.utils.sheet_add_aoa(worksheet, [emptyRow], { origin: -1 });
            //<-----------------Fin fila en blanco----------------->
          }
        }
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button variant="primary" onClick={exportToExcel}>
      Descargar Excel del día
    </Button>
  );
};

export default DownloadExcel;
