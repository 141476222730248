import axios from "axios";
import { useEffect, useState } from "react";
import MySelect from "../Input/Select";
import { FormatDate } from "./FormatDate";
import url from "../../functions/url";

export default function DateAddOrder({
  child,
  dia,
  propertiesOrder,
  cartChild,
  deleteOrder,
  createOrder,
  updateOrder,
  childrenInfoOrders,
  setUpdateSubTotal,
  updateSubTotal,
  fUpdateSubTotal
}) {

  
  //<-------------------------------- search orderId and typeOrderActual------------------------>

  //let arrayTypeFiltered = cartChild.filter((el) => (el.status = "activo"));
 
  let arrayPropertiesFiltered = [];
  let typeOrderActual;
  let orderId;
  let status

  let cart=child.carts?.filter((el) => el.status="activo")
  if (cart.length>0) {
    arrayPropertiesFiltered = cart[0].orders?.filter(
      (el) => el.date?.slice(0, 10) === dia.day.toISOString().slice(0, 10)
    );
    if (arrayPropertiesFiltered?.length > 0) {
      typeOrderActual = arrayPropertiesFiltered[0]?.properties_order?.type;
      orderId = arrayPropertiesFiltered[0]?.id;
      status=arrayPropertiesFiltered[0].status
      }
  }


//<-------------------------------- end search orderId and typeOrderActual------------------------>



  const [input, setInput] = useState({
    type: "Nada",
    status:"nada"
  });

  useEffect(() => {
    if (typeOrderActual) {
      setInput({
        ...input,
        type: typeOrderActual.replace(/£/g, "ú"),
        status:status
      });
    } else {
      setInput({
        ...input,
        type: "Nada",
        status:status
      });
    }
  }, [child?.carts[0]]); 



  async function handleSubmit(daySelected, typeOrder, oldType) {
    if (typeOrder !== oldType) {
      if (typeOrder === "Nada" && oldType !== "Nada") {
        if (child?.carts[0]) {
          //<------------- delete------------------------->
          let arrayOrderDate = child?.carts[0]?.orders?.filter(
            (el) => el.date?.slice(0, 10) === dia.day.toISOString().slice(0,10)
          );
          if (arrayOrderDate.length > 0) {
            deleteOrder(arrayOrderDate[0].id)
          }
        }
      } else if (oldType === "Nada") {
        //<------------- POST------------------------->
      let arrayFilteredPost = propertiesOrder.filter(
          (el) => el.type.replace(/£/g, "ú") === typeOrder         
        ); 
        if(arrayFilteredPost.length>0){
        createOrder(child?.carts[0].id, arrayFilteredPost[0].id, child.id, typeOrder, daySelected, arrayFilteredPost[0].price)
      }
      } else {
        //<------------- PUT-------------------------> 
        let arrayFilteredPut = propertiesOrder.filter((el) => {
          return el.type.replace(/£/g, "ú")  === typeOrder.replace(/£/g, "ú") ;
        });

        let arrayOrderFiltered = child?.carts[0].orders.filter(
          (el) => el.date.slice(0, 10) === daySelected.toISOString().slice(0, 10)
        );

        if(arrayOrderFiltered.length>0){
          updateOrder(orderId, typeOrder, daySelected, arrayFilteredPut[0].id, arrayFilteredPut[0].price)
        }
      }
    }
    fUpdateSubTotal()
  }

  function handleChangeDate(e) {
    const oldType = input.type;
    handleSubmit(dia.day, e.target.value, oldType);
  }
  function handleCancel(e) {
    const oldType = input.type;
    handleSubmit(dia.day, 'Nada', oldType);
  }
  const { nombreDia, fechaFormateada } = FormatDate(dia);

  
  return (
    <div className="mb-3">
      <MySelect
        name="type"
        label={`${nombreDia} ${fechaFormateada}`}
        onChange={(e) => handleChangeDate(e)}
        value={status==="procesado" ? input.type + " - Pedido realizado" : input.type}
        options={status==="procesado" ? [input.type + " - Pedido realizado"] : ["Nada", "Menú semanal", "Vianda"]}
        //options={[input.type + " - Pedido realizado", "Nada", "Menú semanal", "Vianda"]}
        aria-label={dia.day}
        disabled={!dia.status || status==="procesado"}
        //disabled={!dia.status}
        status={status}
      />
      <div className="d-flex justify-content-end">
        {dia.status&&status==="procesado"?<button className={`btn btn-link text-danger`} onClick={(e) => handleCancel(e)}>Cancelar</button>:null}
      </div>
      
    </div>
  );
}
