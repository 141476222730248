import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Logout = () => {
	const { logout } = useAuth();

  return (
		<div className='d-flex' onClick={() => logout()}>
			<ArrowForwardIcon sx={{ color: 'var(--primary)' }} />
			<span className='primary small ms-2'>Cerrar sesión</span>
		</div>
	)
}

export default Logout;