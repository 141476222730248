import React from 'react'

const BannerTop = ({imgSrc, width, title, text}) => {
  return (
    <>
      <div className="title d-flex flex-column align-items-center mt-4">
        <small className="gray fw-medium mb-4">Colegio Español de Rosario</small>
        <img src={imgSrc} width={width}/>
      </div>
      <h1 className="texto-detail-consulta fw-bold text-center mt-2">
        {title}
      </h1>
      {text && 
        <p className='gray text-center fw-medium fw-15' style={{whiteSpace: "pre-line"}}>
          {text}
        </p>
      }
    </>
    
  )
}

export default BannerTop