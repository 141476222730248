export const FormatDate = (dia) => {
  const fecha1=dia.day
  const fechaString =fecha1.toISOString().slice(0,10);
  const partesFecha = fechaString.split("-");
  const fecha = new Date(partesFecha[0], partesFecha[1] - 1, partesFecha[2]);
  const diaSemana = fecha.getDay();
  const nombresDias = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado'
  ];
  const nombreDia = nombresDias[diaSemana];
  const fechaFormateada = ( partesFecha[2]) + '/' + partesFecha[1];
  return {nombreDia, fechaFormateada}
}