import React from 'react'

const MainContainer = ({children, background, paddingX, paddingY = 2}) => {
  return (
    <main className={`container px-${paddingX} py-${paddingY} mb-4`} style={background && {backgroundColor: 'var(--bg-light-gray)'}}>
      {children}
    </main>
  )
}

export default MainContainer