import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import BannerTop from "../BannerTop";
import bannerImg from "../../assets/img/banner-success.svg";
import Divider from "../Divider";
import NextBtn from "../Buttons/NextBtn";
import url from "../../functions/url";

export default function Development() {
  const Navigate = useNavigate();
  const { user,logout } = useAuth();
  return (
    <div className="container">
      <NextBtn text={"Logout"} onClick={async() => { 
        await logout()
        Navigate("/")}} />
      <div className="px-3"></div>
    </div>
  );
}
