import DaySummaryMyOrder from "./DaySummaryMyOrder";
import {
  addDays,
  addWeeks,
  format,
  parse,
  startOfWeek,
  parseISO,
} from "date-fns";
const { es } = require("date-fns/locale");

export default function DayMyOrders({ day, priceMenu, priceVianda, cart }) {
  let orderThisDate = cart[0].orders?.filter( 
    (el) => el.date?.slice(0, 10) === day.toISOString().slice(0, 10) && el.status==="procesado"
  );

  return (
    <div>
      <DaySummaryMyOrder
        weekDay={format(day, "EEEE", { locale: es }).charAt(0).toUpperCase() + format(day, "EEEE", { locale: es }).slice(1)}
        date={format(day, "dd/MM")}
        option={
          orderThisDate[0]?.properties_order.type
            ? orderThisDate[0]?.properties_order.type.replace(/£/g, "ú")
            : "Nada"
        }
        price={
          orderThisDate[0]?.properties_order.type === "Vianda"
            ? priceVianda
            : orderThisDate[0]?.properties_order.type.replace(/£/g, "ú") ===
              "Menú semanal"
            ? priceMenu
            : 0
        }
      />
    </div>
  );
}
