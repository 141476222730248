import axios from "axios"
import { format, getDay, startOfWeek, addWeeks, addDays } from "date-fns";
import { useEffect, useState } from "react";
import url from "./url";

export const useMenus = () => {
  const [ menus, setMenus ] = useState({
    menuJardin: '',
    menuPrimaria: ''
  })
  const getMenus = async () => {
    const response = await axios.get(`${url}/propertiesorderrouter`)
    const menuJardin = response.data?.filter((el) => el.type.replace(/£/g, "ú").replace(/¡/g, "í") === "Menú de Jardín")
    const menuPrimaria = response.data?.filter((el) => el.type.replace(/£/g, "ú") === "Menú de Primaria")

    setMenus({
       menuJardin: menuJardin[0].image,
       menuPrimaria: menuPrimaria[0].image
      //menuJardin: "https://tgqewpehusbfkvybmawi.supabase.co/storage/v1/object/public/colegioespanol/admin/jardin.png",
      //menuPrimaria: "https://tgqewpehusbfkvybmawi.supabase.co/storage/v1/object/public/colegioespanol/admin/primaria.png"
    })
  }

  useEffect(() => {
    getMenus()
  }, [])

  return [menus, setMenus];
}

export const useWeek = () => {
  const today = new Date()
  const dayToday = getDay(today)
  const today9Am = new Date().setHours(8, 59, 59, 999);
  
  const mondayNextWeek = startOfWeek(addWeeks(today, 1), {
    weekStartsOn: 1,
  });
  const mondayNextWeekFormated = format(mondayNextWeek, "yyyy-MM-dd");
  const fridayNextWeek = addDays(mondayNextWeek, 4)
  
  const options = { weekStartsOn: 1 };
  const monday = startOfWeek(today, options);
  const friday = addDays(monday, 4);

  let week;
  let weekInit;
  let weekEnd;
  
  if((dayToday === 5 && today9Am < today) || dayToday === 6 || dayToday === 0) {
    week =  mondayNextWeekFormated;
    weekInit = format(mondayNextWeek, "dd/MM");
    weekEnd = format(fridayNextWeek, "dd/MM");
  } else {
    week = monday.toISOString().slice(0, 10);
    weekInit = format(monday, "dd/MM")
    weekEnd = format(friday, "dd/MM");
  }

  return { week, weekInit, weekEnd }
}
