import React from 'react'

const CardShadow = ({children}) => {
  return (
    <div className='p-3 mb-3' style={{
      background: '#ffffff',
      borderRadius: 4,
      boxShadow: '2px 3px 18px #0000001A'
    }}>
      {children}
    </div>
  )
}

export default CardShadow