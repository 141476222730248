import { Table } from "react-bootstrap";
import Divider from "../../../Divider";

export default function ElementAdminGrade({ commissions }) {

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
          {commissions
          ? commissions[0].columns?.map((el) => {
              return <th key={el}>{el}</th>
            })
          : null}
          </tr>
        </thead>
        <tbody>
        {commissions
        ? commissions[0].data?.map((el, index) => {
          if(el.menu || el.vianda){
            return (
              <tr key={index}>
                <td>{el.nombre}</td>
                <td>{el.menu}</td>
                <td>{el.vianda}</td>
              </tr>
            )
            }
          })
        : null}
        </tbody>
      </Table>
      <Divider />
    </>
  );
}
