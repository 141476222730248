import iconUser from '../../assets/img/icons/icon-user.svg'
import { capitalizeFirstLetter } from '../../functions/utils'

export const UserName = ({user, isAdmin}) => {
	return (
		<div className='d-flex'>
			<img src={iconUser} className='me-3' />
			<span className='fw-bold'>{user?.name && capitalizeFirstLetter(user.name)} {!isAdmin && user?.surname && capitalizeFirstLetter(user.surname)}</span>
		</div>
	)
}