import Lottie from "lottie-react";
import loadingAnimation from '../../lotties/loading-animation.json';
import logo from "../../assets/img/logo.svg";

export const Loading = () => {
	return (
		<div className="d-flex flex-column align-items-center my-5" style={{height: '100vw'}}>
			<img src={logo} alt="Logo Colegio Español" width={155} />
			<h3 className="fw-bold text-center mt-3">
				Cargando los datos, <br/>
				espere por favor.
			</h3>
			<Lottie animationData={loadingAnimation} loop={true} />
			<small className="gray fw-medium">Colegio Español de Rosario</small>
		</div>
	)
}