import Logo from '../Logo'
import { NavCollapse } from '../NavCollapse'
import style from '../Navbar.module.scss'
import { Container, Navbar } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import NavbarToggle from '../NavbarToggle'

const NavbarAdmin = ({user}) => {
  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : 'scroll';
    
    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, [isOpen]);

  return (
    <header>
      <Navbar expand={false} className={`py-0 pb-1 ${style.navbar}`}>
        <Container className={`py-1 ${style.container}`}>
          <Logo isAdmin={true} isOpen={isOpen} className={'p-2'} />
          <NavbarToggle isOpen={isOpen} setIsOpen={setIsOpen} />
          <NavCollapse user={user} isAdmin={true} />
        </Container>
      </Navbar>
    </header>
  )
}

export default NavbarAdmin