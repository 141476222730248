var url;
url="https://colegioespanol.vercel.app"


/*if (process.env.REACT_APP_BACKEND) {
  url="http://localhost:3001"
} else {
  url ="https://colegioespanol.vercel.app";
} */

export default url;
