import React, { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Register from "./Components/Register/Register";
import { AuthProvider } from "./context/AuthContext";
import Home from "./Components/Home/Home";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import Profile from "./Components/Profile/Profile";
import RegisterCompleted from "./Components/RegisterCompleted/RegisterCompleted";
import Payment from "./Components/Payment/Payment";
import OrderCompleted from "./Components/OrderCompleted/OrderCompleted";
import MyOrders from "./Components/MyOrders/MyOrders";
import AddChildren from "./Components/AddChildren/AddChildren";
import WMenuOrder from "./Components/Wmenu/WmenuOrder";
import { ThemeProvider, createTheme } from "@mui/material";
import Summary from "./Components/Summary/Summary";
import Admin from "./Components/Admin/Admin";
import SuperAdmin from "./Components/SuperAdmin/SuperAdmin";
import DesktopError from "./Components/DesktopError";
import TermsAndConditions from "./Components/Terms&Conditions/TermsAndConditions";
import WeeklyMenu from "./Components/Wmenu/WeeklyMenu";
import CheckMail from "./Components/CheckMail/CheckMail";
import CheckMailAfterRegister from "./Components/CheckMailAfterRegister/CheckMailAfterRegister";
import Development from "./Components/Development/Development";
import { auth } from "./firebase";
import axios from "axios";
import url from "./functions/url";
import {userGetAll} from "./actions/index"

 // import { useAuth } from "./context/AuthContext";
import { usuarioA } from "./context/AuthContext";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  const Dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userAdmin, setUsersAdmin] = useState();
  const userData = useSelector((state) => state?.allDataOfUser);
  

  useEffect(() => {
    setUsersAdmin(auth);
    
    if (auth && auth.currentUser && auth.currentUser.email) {
      getUser();
    }
    
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [auth.currentUser]);

  async function getUser() {
    if (!(userData?.length > 0)) {
      Dispatch(userGetAll(auth.currentUser.email));
    }
  }

if(userData?.rol==="admin") {
  console.log("es admin")
} 

  return (
    <ThemeProvider theme={theme}>
      <div name="Colegio Español App">
        <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/checkmail" element={<CheckMail />} />
                <Route path="/development" element={<Development />} />
                <Route
                  path="/checkmailafterregister"
                  element={<CheckMailAfterRegister />}
                />
                <Route path="/register" element={<Register />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/addchildren" element={<AddChildren />} />
                <Route
                  path="/registercompleted"
                  element={<RegisterCompleted />}
                />
                <Route path="/weeklyMenuOrder" element={<WMenuOrder />} />
                <Route path="/weeklyMenu" element={<WeeklyMenu />} />
                <Route path="/summary" element={<Summary />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/ordercompleted/efectivo" element={<OrderCompleted />} />
                <Route path="/ordercompleted/mercado" element={<OrderCompleted />} />
                <Route path="/ordercompleted" element={<OrderCompleted />} />
                <Route path="/myorders" element={<MyOrders />} />
                <Route
                  path="/termsAndConditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/superadmin" element={<SuperAdmin />} />
                <Route path="/admin" element={<Admin />} />
              </Routes>
            </AuthProvider>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
