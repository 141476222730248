import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const CustomCheckbox = styled('span')(({ theme }) => ({
  borderRadius: 8,
  width: 28,
  height: 28,
  backgroundColor: 'var(--secondary)',
  border: '2px solid var(--primary-light)',
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CustomCheckboxChecked = styled(CustomCheckbox)({
  backgroundColor: 'var(--secondary)',
  '&:before': {
    display: 'block',
    width: 27,
    height: 27,
    borderRadius: 8,
    border: '2px solid var(--primary)',
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23434343'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

export function MyCheckbox(props) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<CustomCheckboxChecked />}
      icon={<CustomCheckbox />}
      {...props}
    />
  );
}
