import style from './Navbar.module.scss'
import userEclipse from '../../assets/img/icons/user-eclipse.svg'

export const UserInitials = ({name, surname, isOpen, width, styleClass }) => {
	const nameInitial = name?.slice(0,1).toUpperCase()
	const surnameInitial = surname?.slice(0,1).toUpperCase()
	const nameUser = name?.slice(0,1).toUpperCase() + name?.slice(1)

	return (
		<>
		{
			name && 
			<div className={`${style.userEclipse} ${styleClass === 'profile' && style.profile }`}>
				<div className='position-relative'>
					<img src={userEclipse} width={width} />
					<span className={style.userInitials}>{nameInitial + surnameInitial}</span>	
				</div>
				{!isOpen && <span className='fw-bold ms-2'>¡Hola, {nameUser}!</span> }
			</div>
		}
	</>
	)
}