import { useEffect, useState } from "react";
import { uploadFile } from "../../../firebase";
import axios from "axios";
import url from "../../../functions/url";
import { useMenus } from "../../../functions/hooks";
import ModalChangeMenu from "./ModalChangeMenu";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ChangeWeeklyMenu = () => {
  let [priceMenu, setPriceMenu] = useState(0);
  let [priceVianda, setPriceVianda] = useState(0);

  const [menus, setMenus] = useState({
    menuJardin: "",
    menuPrimaria: "",
    priceMenu: "",
    priceVianda: "",
  });

  const [menusDB, setMenusDB] = useMenus();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const MySwal = withReactContent(Swal);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleMenus = (e) => {
    const { id, files } = e.target;
    const menuType = id === "inputJardin" ? "menuJardin" : "menuPrimaria";

    setMenus((prevState) => ({
      ...prevState,
      [menuType]: files[0],
    }));
  };

  const getMenus = async () => {
    const response = await axios.get(`${url}/propertiesorderrouter`);
    const menuJardin = response.data?.filter(
      (el) => el.type === "Menú de Jardín"
    );
    const menuPrimaria = response.data?.filter(
      (el) => el.type === "Menú de Primaria"
    );

    setMenusDB({
      menuJardinDB: menuJardin[0]?.image,
      menuPrimariaDB: menuPrimaria[0]?.image,
    });
  };


  async function handleSubmitMenu(e) {
    e.preventDefault();
    const idPutJardin = 3;
    const idPutPrimaria = 4;
    try {
      if (
        menus.menuJardin !== menusDB.menuJardinDB &&
        menus.menuJardin !== ""
      ) {
        setIsLoading(true);
        const result = await uploadFile(menus.menuJardin);
        const put = await axios.put(`${url}/admin/image/${idPutJardin}`, {
          urlimage: result,
        });

        if (put.status === 200) {
          setIsLoading(false);
          MySwal.fire({
            title: "¡Menú actualizado!",
            icon: "success",
            confirmButtonColor: "var(--primary)",
          });
        }
      }
      if (
        menus.menuPrimaria !== menusDB.menuPrimariaDB &&
        menus.menuPrimaria !== ""
      ) {
        setIsLoading(true);
        const result = await uploadFile(menus.menuPrimaria);
        const put = await axios.put(`${url}/admin/image/${idPutPrimaria}`, {
          urlimage: result,
        });
        if (put.status === 200) {
          setIsLoading(false);
          MySwal.fire({
            title: "¡Menú actualizado!",
            icon: "success",
            confirmButtonColor: "var(--primary)",
          });
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      MySwal.fire({
        title: "Hubo un error",
        html: (
          <p className="mb-0">
            Inténtelo de nuevo más tarde,
            <br />
            por favor
          </p>
        ),
        icon: "error",
        confirmButtonColor: "var(--primary)",
      });
    }
  }

  useEffect(() => {
    getMenus();
  }, []);
  useEffect(() => {}, [menus]);

  if (isLoading) {
    MySwal.fire({
      title: "Actualizando...",
      icon: "info",
      showConfirmButton: false,
    });
  }

  return (
    <div className="mb-3">
      <button className="btn btn-primary w-100 py-3" onClick={handleShow}>
        Actualizar menú semanal
      </button>
      <ModalChangeMenu
        setMenus={setMenus}
        show={show}
        handleClose={handleClose}
        handleSubmitMenu={(e) => handleSubmitMenu(e)}
        handleMenus={(e) => handleMenus(e)}
      />
    </div>
  );
};

export default ChangeWeeklyMenu;
