import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "../../firebase";
import axios from "axios";
import UpdateChild from "../UpdateChild/UpdateChild";
import url from "../../functions/url";
import Input from "../Input/Input";
import iconEdit from "../../assets/img/icons/icon-edit.svg";
import { UserInitials } from "../Navbar/UserInitials";
import MainContainer from "../Container/MainContainer";
import LayoutLogged from "../Layout/LayoutLogged";
import Divider from "../Divider";
import { Loading } from "../Loading";
import Swal from "sweetalert2"; 
import withReactContent from "sweetalert2-react-content";

export default function Home() {
  const Navigate = useNavigate();
  const { user, loading } = useAuth();
  // const [file, setFile] = useState(null);
  const [userData, setUserData] = useState({});
  const [loadingDb, setLoadingDb] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);
  const MySwal = withReactContent(Swal);

  const [errors, setErrors] = useState({
    dni: "",
    name: "",
    surname: "",
    whatsapp: "",
  });

  const [input, setInput] = useState({
    dni: "",
    name: "",
    surname: "",
    whatsapp: "",
  });

  async function getUser() {
    const userData = await axios.get(`${url}/users/${user.email}`);
    setUserData(userData.data);
    setTimeout(() => {
      setLoadingDb(false);
    }, [1500]);
  }

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...input,
        [e.target.name]: e.target.value,
      })
    );
  }

  async function handleChangeName() {
    const response = await axios.put(`${url}/users/name/${user.email}`, {
      name: input.name,
    });
  }

  async function handleChangeDni() {
    const response = await axios.put(`${url}/users/dni/${user.email}`, {
      dni: input.dni,
    });
  }

  async function handleChangeSurname() {
    const response = await axios.put(`${url}/users/surname/${user.email}`, {
      surname: input.surname,
    });
  }

  async function handleChangeWhatsapp() {
    const response = await axios.put(`${url}/users/whatsapp/${user.email}`, {
      whatsapp: input.whatsapp,
    });
  }

  async function handleSubmit() {
    setSubmitClicked(true);
    const handlers = async () => {
      await handleChangeName();
      await handleChangeSurname();
      await handleChangeDni();
      await handleChangeWhatsapp();
    };
    handlers().then(() => getUser());

    MySwal.fire({
      title: <span className="fw-semibold">Perfil editado!</span>,
      icon: "success",
    });
  }

  useEffect(() => {
    if (userData?.rol === "superadmin") {
      Navigate("superadmin");
    } else if (userData?.rol === "admin") {
      Navigate("/admin");
    } else if (user && !loading) {
      if (!user.emailVerified) {
        Navigate("/checkmail");
      }
    } else if (!user && !loading) {
      Navigate("/");
    }
  }, [userData]);

  useEffect(() => {
    if (user) getUser();
  }, [user]);

  useEffect(() => {
    if (!(Object.keys(userData).length === 0)) {
      setInput({
        ...input,
        dni: userData.dni,
        name: userData.name,
        surname: userData.surname,
        whatsapp: userData.whatsapp,
      });
    }

    return () => {
      setSubmitClicked(false);
    };
  }, [userData]);

  if (!user && !loading) Navigate("/");
  if (loading || loadingDb) return <Loading />;

  return (
    <LayoutLogged>
      <MainContainer paddingX={4}>
        <div className="d-flex align-items-center justify-content-center">
          <UserInitials
            name={userData.name}
            surname={userData.surname}
            isOpen={true}
            styleClass={"profile"}
          />

          <h1 className="fw-bold ms-1" style={{ width: "40%" }}>
            {userData.name} <br />
            {userData.surname}
          </h1>
        </div>
        {/* User Profile Image */}
        {/* <form onSubmit={handleSubmitAvatar}>
          <input
            type="file"
            name=""
            id=""
            onChange={(e) => setFile(e.target.files[0])}
          />
          <button>Upload</button>
        </form>
        {userData ? <img src={userData?.image} alt="avatar" /> : null} */}
        <div>
          <h6 className="fw-medium">Información de mi perfil</h6>
          <Divider width={1} className={"my-1"} />
          <div className="my-4 px-1">
            <div className="mb-3">
              <Input
                name="name"
                label="Nombre"
                type="text"
                onChange={(e) => handleChange(e)}
                value={input.name}
                isInputFilled={input.name !== ""}
                icon={iconEdit}
              />
            </div>

            <div className="mb-3">
              <Input
                name="surname"
                label="Apellido"
                type="text"
                onChange={(e) => handleChange(e)}
                value={input.surname}
                isInputFilled={input.surname !== ""}
                icon={iconEdit}
              />
            </div>

            <div className="mb-3">
              <Input
                name="dni"
                label="DNI"
                type="text"
                onChange={(e) => handleChange(e)}
                value={input.dni}
                isInputFilled={input.dni !== ""}
                icon={iconEdit}
              />
            </div>

            <div className="mb-3">
              <Input
                name="whatsapp"
                label="Número de WhatsApp"
                type="number"
                onChange={(e) => handleChange(e)}
                value={input.whatsapp}
                isInputFilled={input.whatsapp !== ""}
                icon={iconEdit}
              />
            </div>
          </div>
        </div>
        <div>
          <h6 className="fw-medium">Mis hijos</h6>
          <Divider width={1} className={"my-1"} />
          <div className="px-1">
            {userData
              ? userData.children.map((el) => {
                  return (
                    <div className="mt-3">
                      <UpdateChild
                        key={el.id}
                        id={el.id}
                        name={el.name}
                        grade={el.grade}
                        commission={el.commission}
                        submitBtnClicked={submitClicked}
                      />
                      {userData.children.indexOf(el) !==
                        userData.children.length - 1 && (
                        <hr
                          className="my-4"
                          style={{ border: "1px solid #2D2D2D", opacity: 0.3 }}
                        />
                      )}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <Divider width={1} className={"mt-4"} />
        <div className="my-4">
          <button
            button
            className="btn btn-primary py-3 w-100"
            onClick={() => {
              handleSubmit();
            }}
          >
            Guardar
          </button>
        </div>
        <Divider width={1} className={"mt-4"} />
        <div className="my-4">
          <button
            button
            className="btn btn-primary py-3 w-100"
            onClick={() => {
              Navigate("/addchildren");
            }}
          >
            Agregar hijos/as
          </button>
        </div>
      </MainContainer>
    </LayoutLogged>
  );
}

function validarCorreo(valor) {
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valor)) {
    return false;
  } else {
    return true;
  }
}

function validate(input) {
  let errors = {};
  if (!input.name) {
    errors.name = "Este campo es requerido";
  } else if (!input.surname) {
    errors.surname = "Este campo es requerido";
  } else if (!input.password) {
    errors.password = "Este campo es requerido";
  } else if (input.password.length < 6) {
    errors.password = "La contraseña debe ser de al menos seis caracteres ";
  } else if (input.password !== input.confirmpassword) {
    errors.confirmpassword = "Las contraseñas no coinciden ";
  } else if (!input.dni) {
    errors.dni = "Este campo es requerido";
  } else if (!input.whatsapp) {
    errors.whatsapp = "Este campo es requerido";
  } else if (!input.mail) {
    errors.mail = "Este campo es requerido";
  } else if (validarCorreo(input.mail)) {
    errors.mail = "Debe ingresar un email válido";
  } else if (input.aceptaterminos === false) {
    errors.aceptaterminos = "Debe aceptar los términos y condiciones";
  }
  return errors;
}

// async function handleSubmitAvatar(e) {
//   e.preventDefault();
//   try {
//     const result = await uploadFile(file);
//     console.log(result);
//     const saveUrl = await axios.put(
//       `https://colegioespanol.vercel.app/users/updateimage/${user.email}`,
//       /* `http://localhost:3001/users/updateimage/${user.email}`, */
//       { image: result }
//     );
//     const userData = await axios.get(
//       `https://colegioespanol.vercel.app/users/${user.email}`
//       /* `http://localhost:3001/users/${user.email}` */
//     );
//     setUserData(userData.data);
//   } catch (error) {
//     console.log(error);
//   }
// }
