import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import BannerTop from "../BannerTop";
import bannerImg from "../../assets/img/banner-success.svg";
import Divider from "../Divider";
import NextBtn from "../Buttons/NextBtn";


export default function CheckMailAfterRegister() {
  const Navigate = useNavigate();
  const { user,logout } = useAuth();

  if (user && user?.emailVerified) Navigate("/addchildren");
  return (
    <div className="container">
      <BannerTop 
        imgSrc={bannerImg}
        width={"75%"}
        title={"Revisa tu correo electrónico"}
        text={
          <span className="px-3">
            Te hemos enviado un correo para verificar tu cuenta
            a  <br /><span className="fw-bold">{user?.email}.&nbsp;</span>
            <br />
            Por favor revísalo.
          </span>
        }
      />
      <NextBtn text={"Volver a Login"} onClick={async() => {
        await logout()
        Navigate("/")}} />
      <div className="px-3"></div>
    </div>
  );
}
