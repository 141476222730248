import * as React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import style from './Input.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const CustomSelect = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    color: 'var(--font-color)',
    backgroundColor: 'var(--secondary)',
    borderColor: 'var(--primary-light)',
    border: '2px solid var(--primary)',
    fontSize: 15,
    fontWeight: 500,
    padding: '10px 26px 10px 12px',
    width: '100%',
    margin: 0,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 8,
      borderColor: 'var(--primary)',
      boxShadow: '0 0 0 0.2rem rgba(255, 56, 56, .25)',
      backgroundColor: 'var(--secondary)'
    },
  },
  '& .MuiSvgIcon-root': {
    right: '10px'
  }
}));

export default function MySelect({value, defaultValue, onChange, label, name, options, error, disabled, status}) {
  const [iconOpacity, setIconOpacity] = React.useState('.5')
  const selectedValue = value || defaultValue;


  return (
    <div>
      <FormControl variant='standard' className='w-100'>
        <label htmlFor={name} className={style.selectLabel}>{label}</label>
        <Select
          name={name}
          labelId={`${label}Label`}
          label={label}
          value={selectedValue}
          onChange={onChange}
          input={<CustomSelect hasValue={selectedValue} />}
          className={`mt-1`}
          IconComponent={() => (
            <KeyboardArrowDownIcon className='position-absolute' style={{opacity: iconOpacity}} />
          )}
          onFocus={() => setIconOpacity(1)}
          onBlur={() => selectedValue === '' && setIconOpacity(.5)}
          disabled={disabled}
        >
          {options.map((el) => {
            return <MenuItem value={el}>{el}</MenuItem>;
          })}
        </Select>
        {error && (
          <small style={{ color: "var(--bs-danger)" }} className="mb-0">*{error}</small>
        )}
      </FormControl>
    </div>
  );
}