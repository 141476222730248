const initialState = {
  allDataOfUser: [],
  ordersMenuPending: [],
  ordersMenuActive: [],
  ordersViandaPending: [],
  ordersViandaActive: [],
  totalActiveVianda: 0,
  totalPendingMenu: 0,
  totalActiveMenu: 0,
  totalPendingVianda: 0,
  menuPrice: 0,
  viadnaPrice: 0,
  user: {},
  loader: true,
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_OF_USER":
      const activeArrayMenu = [];
      const pendingArrayVianda = [];
      const pendingArrayMenu = [];
      const activeArrayVianda = [];
      
      const children = action.payload?.children;
      const activeOrders = [];
      let pendingTotalVianda = 0;
      let pendingTotalMenu = 0;
      let activeTotalVianda = 0;
      let activeTotalMenu = 0;
      for (const child of children) {
        const carts = child.carts;
        for (let i = 0; i < carts.length; i++) {
          for (let j = 0; j < carts[i].orders?.length; j++) {
            if (
              carts[i].orders[j].status === "activo" &&
              carts[i].orders[j].properties_order?.type === "Vianda"
            ) {
              activeArrayVianda.push(carts[i].orders[j]);
              activeTotalVianda++;
            }
            if (
              carts[i].orders[j].status === "procesado" &&
              carts[i].orders[j].properties_order?.type === "Vianda"
            ) {
              pendingArrayVianda.push(carts[i].orders[j]);
              pendingTotalVianda++;
            }
            if (
              carts[i].orders[j].status === "activo" &&
              carts[i].orders[j].properties_order?.type.replace(/£/g, "ú") ===
                "Menú semanal"
            ) {
              activeArrayMenu.push(carts[i].orders[j]);
              activeTotalMenu++;
            }
            if (
              carts[i].orders[j].status === "procesado" &&
              carts[i].orders[j].properties_order?.type.replace(/£/g, "ú") ===
                "Menú semanal"
            ) {
              pendingArrayMenu.push(carts[i].orders[j]);
              pendingTotalMenu++;
            }
          }
        }
      }

      let activeArray = [];
      action.payload?.children.forEach((child) => {
        child.carts.forEach((cart) => {
          if (cart.orders) {
            cart.orders.forEach((order) => {
              console.log("order en reducer", order);
              if (order.status === "activo") {
                activeArray.push(order);
              }
            });
          }
        });
      });

      return {
        ...state,
        allDataOfUser: action.payload,
        totalActiveVianda: activeTotalVianda,
        totalPendingMenu: pendingTotalMenu,
        totalActiveMenu: activeTotalMenu,
        totalPendingVianda: pendingTotalVianda,
        ordersMenuPending: pendingArrayMenu,
        ordersMenuActive: activeArrayMenu,
        ordersViandaPending: pendingArrayVianda,
        ordersViandaActive: activeArrayVianda,
      };

    case "GET_USER_ALL": {
      const activeArrayMenu = [];
      const pendingArrayVianda = [];
      const pendingArrayMenu = [];
      const activeArrayVianda = [];
      const children = action.payload?.children;
      const activeOrders = [];
      let pendingTotalVianda = 0;
      let pendingTotalMenu = 0;
      let activeTotalVianda = 0;
      let activeTotalMenu = 0;
      for (let i = 0; i < children?.length; i++) {
        for (let j = 0; j < children[i].orders?.length; j++) {
          const today = new Date();

          if (
            children[i].orders[j].status === "activo" &&
            children[i].orders[j].properties_order?.type === "Vianda"
          ) {
            
              activeArrayVianda.push(children[i].orders[j]);
              activeTotalVianda++;
            
          }
          if (
            children[i].orders[j].status === "procesado" &&
            children[i].orders[j].properties_order?.type === "Vianda"
          ) {
            pendingArrayVianda.push(children[i].orders[j]);
            pendingTotalVianda++;
          }
          if (
            children[i].orders[j].status === "activo" &&
            children[i].orders[j].properties_order?.type.replace(/£/g, "ú") ===
              "Menú semanal"
          ) {
            
              activeArrayMenu.push(children[i].orders[j]);
              activeTotalMenu++;
            
          }
          if (
            children[i].orders[j].status === "procesado" &&
            children[i].orders[j].properties_order?.type.replace(/£/g, "ú") ===
              "Menú semanal"
          ) {            
            pendingArrayMenu.push(children[i].orders[j]);
            pendingTotalMenu++;
          }
        }
      }
      console.log("order en reducer2");
      return {
        ...state,
        allDataOfUser: action.payload,
        totalActiveVianda: activeTotalVianda,
        totalPendingMenu: pendingTotalMenu,
        totalActiveMenu: activeTotalMenu,
        totalPendingVianda: pendingTotalVianda,
        ordersMenuPending: pendingArrayMenu,
        ordersMenuActive: activeArrayMenu,
        ordersViandaPending: pendingArrayVianda,
        ordersViandaActive: activeArrayVianda,
      };
    }
    case "UPDATE_PRICE_VIANDA":
      {
      }
      return {
        ...state,
        viandaPrice: action.payload,
      };

    case "UPDATE_PRICE_MENU":
      {
      }
      return {
        ...state,
        menuPrice: action.payload,
      };

    case "GET_PROPERTIES_ORDER":
      return {
        ...state,
      };
    case "USER_ADMIN":
      return {
        ...state,
        user: action.payload,
      };
  }
}
