import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import LayoutLogged from "../Layout/LayoutLogged";
import PrevBtnOutline from "../Buttons/PrevBtn";
import BtnOption from "../Buttons/BtnOption";
import MainContainer from "../Container/MainContainer";
import chefImg from "../../assets/img/chef-img.svg";
import url from "../../functions/url.js";
import TitleSmall from "../Text/TitleSmall";
import { addDays, addWeeks, format, parse, startOfWeek } from "date-fns";
import { getDay } from "date-fns"; 
import { useDispatch } from "react-redux";
import { getAllOfUser } from "../../actions/index";
import { Loading } from "../Loading";

export default function Payment() {
  const Dispatch = useDispatch();
  const Navigate = useNavigate();
  const [peticiones, setPeticiones] = useState(false);
  const [userData, setUserData] = useState([]);
  const { user, loading } = useAuth();
  const [isHidden, setIsHidden] = useState(true);
  if (!loading && !user) {
    Navigate("/");
  }

  useEffect(() => {
    if (user && !loading) {
      getUser(user.email);
    }
  }, [loading]);

  if(!user && !loading) Navigate("/")
  else if(user && !user?.emailVerified) Navigate("/checkmail")

  async function getUser() {
    if (user) {
      if (!(userData?.length > 0)) {
        Dispatch(getAllOfUser(user.email));
        setIsHidden(false);
      }
    }
  }

  
  useEffect(() => {
    if (userData?.rol === "superadmin") {
      Navigate("/superadmin");
    } else if (userData?.rol === "admin") {
      Navigate("/admin");
    }
  }, [userData]);

  let today = new Date();
  const todayFormat = format(today, "dd/MM");

  const mondayNextWeek = startOfWeek(addWeeks(today, 1), {
    weekStartsOn: 1,
  });
  const mondayNextWeekFormated = format(mondayNextWeek, "yyyy-MM-dd");



  const options = { weekStartsOn: 1 };

  const monday = startOfWeek(today, options);
  let tomorrowNineAm = addDays(today, 1);
  tomorrowNineAm.setHours(8, 59, 59, 999);
  let todayNineAm = new Date();
  todayNineAm.setHours(8, 59, 59, 999);
  let nDayToday = getDay(today);
  let dayTodayIterable;
  let friday = addDays(monday, 5);
  const fridayNextWeek = addDays(mondayNextWeek, 5);
  let week
  let weekInit;
  let weekEnd;
  let sendWeekEnd;
  if (
    (nDayToday === 5 && todayNineAm < today) ||
    nDayToday === 6 ||
    nDayToday === 0
  ) {

    week = mondayNextWeekFormated;
    weekInit = format(mondayNextWeek, "dd/MM");
    weekEnd = format(fridayNextWeek, "dd/MM");
    sendWeekEnd= format(fridayNextWeek, "yyyy-MM-dd");
  } else {
    week = monday.toISOString().slice(0, 10);
    weekEnd = format(friday, "dd/MM");
    sendWeekEnd=format(friday, "yyyy-MM-dd");
  }

  async function getUser(mail) {
    // const userDataApi = await axios.get(`${url}/users/carts/${user.email}`);
    // setUserData(userDataApi);
    setPeticiones(true);
  }

  async function efectivo() {
    try {
      const response = await axios.post(`${url}/cash`, {
        mail: user.email,
        startOfDay: week,
        endOfDay: sendWeekEnd,
      });
      Navigate("/ordercompleted/efectivo");
    } catch {
      alert("No se pudo procesar el pago");
    }
  }

  async function transferencia() {
    try {
      const response = await axios.post(`${url}/wiretransfer`, {
        mail: user.email,
        startOfDay: week,
        endOfDay: sendWeekEnd,
      });
      Navigate("/ordercompleted");
    } catch {
      alert("No se pudo procesar el pago");
    }
  }

  async function mercadoPago() {
    //const response = await axios.post(`${url}/mercadopago/create-order`, {
    //  mail: user.email,
    //  startOfDay: week,
    //  endOfDay: sendWeekEnd,
    //});

    //if (response?.data?.back_urls?.success) {
    //  window.open(response.data.init_point, "_blank");
    //}

    try {
      const response = await axios.post(`${url}/wiretransfer`, {
        mail: user.email,
        startOfDay: week,
        endOfDay: sendWeekEnd,
      });
      Navigate("/ordercompleted/mercado");
    } catch {
      alert("No se pudo procesar el pago");
    }
    
  }

  if (!peticiones && user) getUser();
  if (loading) return <Loading />;

  return (
    <LayoutLogged>
      <MainContainer paddingX={4} background={true}>
        <PrevBtnOutline
          text={<span className="small">Volver a editar el pedido</span>}
          onClick={() => Navigate("/weeklymenuorder")}
          style={{ paddingLeft: 0 }}
        />
        <TitleSmall
          divClassName={"mt-3"}
          title={"¿Cómo quieres realizar el pago?"}
          subtitle={"Seleccionar medio de pago"}
        />
        <div className="mt-2">
          <BtnOption
            text={"Mercado Pago"}
            className={"btn-MercadoPago"}
            onClick={() => mercadoPago()}
          />
          <BtnOption
            text={"Transferencia Bancaria"}
            className={"btn-primary mt-4"}
            onClick={() => transferencia()}
          />
          <BtnOption
            text={"Efectivo de forma presencial"}
            className={"btn-secondary mt-4"}
            onClick={() => efectivo()}
          />
        </div>
        <div className="mt-5 mb-4">
          <img src={chefImg} className="w-100" />
        </div>
      </MainContainer>
    </LayoutLogged>
  );
}
