import React, { useEffect, useState } from "react";
import CardShadow from "../Cards/CardShadow";
import Divider from "../Divider";
import Day from "./DayComponent";
import { addDays, addWeeks, format, parseISO, startOfWeek } from "date-fns";
const { es } = require("date-fns/locale");


const SummaryCard = ({ fullName, carts, priceMenu, priceVianda, weekInfo }) => {
  const cart = carts.filter((el) => (el.status = "activo"));
  let [countVianda, setCountVianda] = useState(0);
  let [countMenu, setCountMenu] = useState(0);
const ordersFiltered=cart.filter
  useEffect(() => {
    let viandaCount = 0;
    let menuCount = 0;
    if (cart.length > 0) {

      for (let i = 0; i < cart[0].orders.length; i++) {
        if (cart[0].orders[i].properties_order.type === "Vianda" && cart[0].orders[i].status==="activo") {
          viandaCount++;
        }
        if (
          cart[0].orders[i].properties_order.type.replace(/£/g, "ú") ===
          "Menú semanal" && cart[0].orders[i].status==="activo"
        ) {
          menuCount++;
        }
      }
      setCountMenu(menuCount);
      setCountVianda(viandaCount);

    }
  }, []);


  return (
    <div className="mt-4">
      <CardShadow>
        <div className="px-2">
          <span className="fw-bold">{fullName}</span>
          <hr style={{ background: "rgba(45, 45, 45, .1)" }} className="mt-2" />
          <div className="pt-2">
            {weekInfo
              ? weekInfo.arrayDays?.map((dia) => {
                  return (
                    <>
                      <Day
                        day={dia.day}
                        priceMenu={priceMenu}
                        priceVianda={priceVianda}
                        cart={cart}
                      />
                    </>
                  );
                })
              : null}
          </div>
        </div>
      </CardShadow>
      <div className="d-flex justify-content-end me-1">
        <span className="gray fw-bold">
          Subtotal: $
          {countVianda * Number(priceVianda) + countMenu * Number(priceMenu)}.-
        </span>
      </div>
      <Divider width={1} />
    </div>
  );
};

export default SummaryCard;
 