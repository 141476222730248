// import mailjet from 'node-mailjet'

import Mailjet from 'node-mailjet'

/**
 *
 * This call sends a message to one recipient.
 *
 */

export function sendConfirmationEmail(toEmail, toName){
  let mailjet = require('node-mailjet')
  
  mailjet = new Mailjet.apiConnect("5e06a47f49d3537a5ca4dde16ab968c9", 
  "c592afe8edd33f2d489ed85d874c29b3");

  const request = mailjet
    .post("send", {'version': 'v3.1'})
    .request({
      "Messages":[
        {
          "From": {
            "Email": "emely.mack@axyomaglobal.com",
            "Name": "Emely Mack"
          },
          "To": [
            {
              "Email": "emelymack@gmail.com",
              "Name": "passenger 1"
            }
          ],
          "Subject": "Your email flight plan!",
          "TextPart": "Dear passenger 1, welcome to Mailjet! May the delivery force be with you!",
          "HTMLPart": "<h3>Dear passenger 1, welcome to <a href=\"https://www.mailjet.com/\">Mailjet</a>!</h3><br />May the delivery force be with you!"
      }
		]
	})
  request
	.then((result) => {
		console.log(result.body)
	})
	.catch((err) => {
		console.log(err.statusCode)
	})
}
