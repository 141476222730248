import { useAuth } from "../../context/AuthContext";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import bannerImg from "../../assets/img/banner-children.svg";
import BannerTop from "../BannerTop";
import { Box } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChildList from "./ChildList";
import url from "../../functions/url";
import { getAllOfUser } from "../../actions/index";
import { useDispatch, useSelector } from "react-redux";
import {sendConfirmationEmail} from '../Register/MailjetConfig'




export default function AddChildren() {
  const Dispatch = useDispatch();
  const { user, loading } = useAuth();
  const Navigate = useNavigate();
  const [loadUser, setLoadUser] = useState(false);
  const [globalError, setGlobalError] = useState([1]);
  const [arrayChildren, setArrayChildren] = useState([]);
  const [numChildren, setNumChildren] = useState(1);
  const [userData, setUserData] = useState({
    dni: "",
    children: [],
  });

  const dataUser = useSelector((state) => state?.allDataOfUser);

  if (user && !loadUser) {
    getUser(user.email);
    getUserRedux(user.email);
    setLoadUser(true);
  }

  useEffect(() => {
    if (userData?.rol === "superadmin") {
      Navigate("superadmin");
    } else if (userData?.rol === "admin") {
      Navigate("/admin");
    } else if (user && !loading) {
      if (!user.emailVerified) {
        Navigate("/checkmail");
      //} else if (userData?.children?.length > 0) {
      // Navigate("/weeklymenuorder");
      } else {
        Navigate("/addchildren");
      }
    } else if (!user && !loading) {
      Navigate("/");
    }
  }, [userData]);

  async function getUser(mail) {
    let data = await axios.get(`${url}/users/${mail}`);
    setUserData({
      ...userData,
      dni: data.data.dni,
      children: data.data.children,
    });
  }

  async function getUserRedux() {
    if (user) {
      if (!(dataUser?.length > 0)) {
        Dispatch(getAllOfUser(user.email));
      }
    }
  }

  // useEffect(() => {
  //   if (dataUser?.rol === "superadmin") {
  //     Navigate("/superadmin");
  //   } else if (dataUser?.rol === "admin") {
  //     Navigate("/admin");
  //   }
  // }, [dataUser]);

  async function handleSubmit(e) {
    e.preventDefault(e);
    // try {
    await axios.post(`${url}/child/${userData.dni}`, {
      children: arrayChildren,
    });
    sendConfirmationEmail(dataUser.mail, dataUser.name)
    Navigate("/registercompleted");
  }

  // console.log("user data", userData)
  console.log("user data", userData);
  let itemInErrorArray = [];
  let arrayChildrenSendToBack = [];

  function errorsGlobal(errors, input, id) {
    //<----------------------Manejo de erores global-------------------->
    if (
      errors.name ||
      errors.grade ||
      errors.commission ||
      !input.name ||
      !input.grade ||
      !input.commission
    ) {
      // se busca el id en el arreglo, si no se encuentro se agrega.
      itemInErrorArray = globalError.find((item) => item === id);
      if (!itemInErrorArray) {
        setGlobalError([...globalError, id]);
      }
    } else {
      // se busca el id en el arreglo, si se encuentra se saca.
      itemInErrorArray = globalError.find((item) => item === id);
      if (itemInErrorArray) {
        setGlobalError(globalError.filter((el) => el !== id));
      }
    }
    //<---------------------- Fin Manejo de erores global-------------------->
  }

  function updateArrayChildren(name, grade, commission, id) {
    if (id) {
      let childrenFiltered = arrayChildren.find((item) => item.id === id);
      if (childrenFiltered) {
        arrayChildrenSendToBack = arrayChildren.map((item) =>
          item.id === id
            ? {
                ...item,
                name: name,
                grade: grade,
                commission: commission,
              }
            : item
        );
        setArrayChildren(arrayChildrenSendToBack);
      } else {
        setArrayChildren([
          ...arrayChildren,
          {
            id: id,
            name: name,
            grade: name,
            commission: name,
          },
        ]);
      }
    }
  }

  const agregarInput = () => {
    const nuevoInput = {
      id: numChildren.length + 1,
    };
    setNumChildren([...numChildren, nuevoInput]);
  };
  const textButton = userData?.children?.length > 0 ? "Actualiza tu cuenta" : "Crea tu cuenta";
  return (
    <div className="container mb-5">
      <BannerTop
        imgSrc={bannerImg}
        width={"80%"}
        title={"¡Excelente!"}
        text={`Sólo falta un paso más,\nahora sólo debés agregar a tus hijos/as`}
      />
      <Box component="form" autoComplete="off" className="px-3 mt-4 pt-2">
        <ChildList
          arrayChildren={arrayChildren}
          setArrayChildren={setArrayChildren}
          globalError={globalError}
          setGlobalError={setGlobalError}
        />
        <button
          className="btn btn-primary mt-3 w-100 py-3"
          type="submit"
          disabled={globalError.length > 0 ? true : false}
          onClick={(e) => handleSubmit(e)}
        >
          {textButton}  
          <ArrowForwardIcon className="ms-2" style={{ fontSize: "1.5rem" }} />
        </button>
      </Box>
    </div>
  );
}
