import style from './Navbar.module.scss'
import arrowLink from '../../assets/img/icons/nav-arrow.svg'
import { Nav } from 'react-bootstrap'
import { useNavigate } from 'react-router'


export const NavItem = ({href, icon, linkText, route}) => {
	const Navigate=useNavigate()
	return (
		<Nav.Link href={href} className={style.navLink} onClick={() => Navigate(route)}>
			<div>
				<img src={icon} className="me-3" />
				{linkText}
			</div>
			<img src={arrowLink} />
		</Nav.Link>
	)
}