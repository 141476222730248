import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MenuCarousel from "./MenuCarousel";
import LayoutLogged from "../Layout/LayoutLogged";
import TitleSmall from "../Text/TitleSmall";
import MainContainer from "../Container/MainContainer";
import Divider from "../Divider";
import HomeBtn from "../Buttons/HomeBtn";
import BtnWhatsapp from "../Buttons/BtnWhatsapp";
import { Loading } from "../Loading";
import { addDays, addWeeks, format, parse, startOfWeek } from "date-fns";
import { getDay } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOfUser } from "../../actions/index";

export default function WeeklyMenu() {
  const Dispatch = useDispatch();
  const Navigate = useNavigate();
  const { loading, user } = useAuth();
  const [isHidden, setIsHidden] = useState(true);
  const [loadUser, setLoadUser] = useState(false);
  const userData = useSelector((state) => state?.allDataOfUser);
 
  
  
  if(!user && !loading) Navigate("/")
  else if(user && !user?.emailVerified) Navigate("/checkmail")

  useEffect(() => {
    if (user && !loading) {
      getUser(user.email);
    }
  }, [loading]);


  async function getUser() {

    if (user) {
      if (!(userData?.length > 0)) {
        Dispatch(getAllOfUser(user.email));
        setIsHidden(false);
      }
    }
  }
  useEffect(() => {
    if (userData?.rol === "superadmin") {
      Navigate("superadmin");
    } else if (userData?.rol === "admin") {
      Navigate("/admin");
    } else if (user && !loading) {
      if (!user.emailVerified) {
        Navigate("/checkmail");
      }
    } else if (!user && !loading) {
      Navigate("/");
    }
  }, [userData]);


  let today = new Date();

  const mondayNextWeek = startOfWeek(addWeeks(today, 1), {
    weekStartsOn: 1,
  });

  const mondayNextWeekFormated = format(mondayNextWeek, "yyyy-MM-dd");

  const fridayNextWeek = startOfWeek(addWeeks(today, 1), {
    weekStartsOn: 5,
  });

  const friday = startOfWeek(addWeeks(today, 5), {
    weekStartsOn: 1,
  });

  const options = { weekStartsOn: 1 };

  const monday = startOfWeek(today, options);

  let todayNineAm = new Date();
  todayNineAm.setHours(8, 59, 59, 999);
  let nDayToday = getDay(today);
  let week;
  let weekInit;
  let weekEnd;

  if ((nDayToday === 5 && todayNineAm < today) || nDayToday === 6) {
    week = mondayNextWeekFormated;
    weekInit = format(mondayNextWeek, "dd/MM");
    weekEnd = format(fridayNextWeek, "dd/MM");
  } else {
    week = monday.toISOString().slice(0, 10);
    weekInit = format(monday, "dd/MM");
    weekEnd = format(friday, "dd/MM");
  }

  if (loading || isHidden) return <Loading />;

  return (
    <>
      {!isHidden && (
        <LayoutLogged>
          <MainContainer paddingX={4} background={true}>
            <div className="position-relative pt-1">
              <HomeBtn smaller={true} />
              <TitleSmall
                title={"Menú Semanal disponible"}
                subtitle={
                  <span>
                    Semana&nbsp;
                    <span id="week-start">{weekInit}</span>
                    &nbsp;a&nbsp;
                    <span id="week-end">{weekEnd}</span>
                  </span>
                }
              />
            </div>
            <Divider width={1} className={"mt-3 mb-4"} />
            <section className="menuCarousel mt-2">
              <MenuCarousel />
            </section>
            <BtnWhatsapp variant={"primary"} />
          </MainContainer>
        </LayoutLogged>
      )}
    </>
  );
}


