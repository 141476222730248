import React from 'react'

const TitleSmall = ({divClassName, title, subtitle}) => {
  return (
    <div className={divClassName}>
      <h6 className="fw-bold mb-1">{title}</h6>
      <p className="light-gray smaller mb-0">{subtitle}</p>
    </div>
  )
}

export default TitleSmall