import { useLocation, useNavigate, useNavigation } from 'react-router-dom'
import imgSuccess from '../../assets/img/banner-success.svg'
import warning from '../../assets/img/icons/warning-icon.svg'
import MainContainer from '../Container/MainContainer'
import LayoutLogged from '../Layout/LayoutLogged'
import Divider from '../Divider'
import BtnWhatsapp from '../Buttons/BtnWhatsapp'
import HomeBtn from '../Buttons/HomeBtn'

export default function OrderCompleted() { 
	const Navigate=useNavigate()
	const location = useLocation();
	const pagaEfectivo = location.pathname.includes('efectivo');
	const pagaMP = location.pathname.includes('mercado');

	return(
		<LayoutLogged>
      <MainContainer paddingX={4}>
        <HomeBtn />
        <div className="title d-flex flex-column align-items-center mt-3">
					<img src={imgSuccess} width={'60%'}/>
					<h1 className="texto-detail-consulta fw-bold text-center my-3">
						¡Pedido realizado!
					</h1>
				</div>
				<div>
					<p className='gray fw-medium text-center mb-3'>Ya podés ver tu pedido semanal en</p>
					<button className='btn btn-primary py-3 w-100' onClick={() => Navigate('/myOrders')}>Mis pedidos</button>
				</div>
				<Divider width={2} className={'my-4'} />
				<div className='d-flex flex-column align-items-center'>
					<div className={`${pagaEfectivo||pagaMP ? 'd-none' : 'd-flex flex-column align-items-center'}`}>
						<img src={warning} />
						<p className='text-center gray fs-15 mt-3 fw-medium'>
							Sólo falta que nos envies el comprobante de pago de la transferencia bancaria vía WhatsApp así confirmamos la recepción del mismo.
						</p>
						<p className='text-center gray fw-bold'>
							Datos Bancarios<br/>
							CBU: 0170081740000049604425 <br/>
							Alias: CRISTIANCANTINA<br/>
							Titular de la cuenta: GAGO CRISTIAN JOSE <br/>
							Numero de documento: 22685313 <br/>
							CUIL/CUIT/CDI: 20226853139 <br/>
							Tipo de cuenta: Caja de Ahorro en $ <br/>
							Numero de cuenta: 81-496044/2 <br/>
							Es Cuenta Corriente: No <br/>

						</p>
					</div>
					<div className={`${!pagaMP ? 'd-none' : 'd-flex flex-column align-items-center'}`}>
						<img src={warning} />
						<p className='text-center gray fs-15 mt-3 fw-medium'>
							Sólo falta que nos envies el comprobante de pago en Mercado Pago vía WhatsApp así confirmamos la recepción del mismo.
						</p>
						<p className='text-center gray fw-bold'>
							Datos Mercado Pago <br/>
							Cristian Jose Gago <br/>
							CVU: 0000003100023527453456 <br/>
							Alias: fridacanaya <br/>
							CUIT/CUIL: 20226853139 <br/>
						</p>
					</div>
					{/* FALTA LINK A WHATSAPP */}
					<BtnWhatsapp variant={'secondary'} />
					<p className={`text-center gray fs-15 mt-3 fw-medium mb-0 ${!pagaEfectivo && 'd-none'}`}>
						En caso que pagues en efectivo en la escuela, el pedido se confirmará una vez hecho el pago
					</p>
				</div>
      </MainContainer>
    </LayoutLogged>
	)
}
