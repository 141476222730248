import React, { useEffect, useState } from 'react'
import NavbarLogged from '../Navbar/NavbarLogged'
import Footer from '../Footer'
import NavbarAdmin from '../Navbar/Admin/NavbarAdmin';
import { useSelector } from 'react-redux';

const LayoutLogged = ({children}) => {
  const userData = useSelector((state) => state?.allDataOfUser);

  return (
    <>
      {userData?.rol !== "admin" ? <NavbarLogged user={userData} /> : <NavbarAdmin user={userData} />}
        {children}
      <Footer />
    </>
  )
}

export default LayoutLogged