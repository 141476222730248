import iconHome from '../../assets/img/icons/icon-home.svg'
import iconMenu from '../../assets/img/icons/icon-menu.svg'
import iconList from '../../assets/img/icons/icon-list.svg'
import iconUser from '../../assets/img/icons/icon-user.svg'
import iconInfo from '../../assets/img/icons/icon-info.svg'
import { NavItem } from "./NavItem"

export const NavList = () => {
	const setScroll = () => {
		document.body.style.overflowY = 'scroll'
	}
	return (
		<div>
			<NavItem icon={iconHome} linkText={'Inicio'} route="/weeklymenuorder" onClick={setScroll()} />
			<NavItem icon={iconMenu} linkText={'Menú Semanal disponible'} route="/weeklymenu" onClick={setScroll()} />
			<NavItem icon={iconList} linkText={'Mis pedidos'} route="/myorders" onClick={setScroll()} />
			<NavItem icon={iconUser} linkText={'Mi Perfil'} route="/profile" onClick={setScroll()} />
			{/* <NavItem icon={iconInfo} linkText={'Información útil'} route="/termsAndConditions" onClick={setScroll()} /> */}
		</div>
	)
}
