import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import BannerTop from "../BannerTop";
import bannerImg from "../../assets/img/banner-success.svg"
import Divider from "../Divider";
import NextBtn from "../Buttons/NextBtn";

export default function RegisterCompleted() {
  const Navigate = useNavigate();
  const { user } = useAuth();

  if (!user) Navigate("/");
  return (
    <div className="container">
      <BannerTop imgSrc={bannerImg} width={'75%'} title={'¡Registro Completado!'} />
      <div className="px-3">
        <Divider width={2} className='my-4' />
        <div className="d-flex justify-content-center mb-3">
          <p className="text-center gray fw-medium" style={{width:'90%'}}>
            Ya puedes acceder a la plataforma para pedir los menúes que quieras de esta semana
          </p>
        </div>
        
        <NextBtn text={'Acceder'} onClick={() => Navigate("/weeklymenuorder")} />
      </div>
      
    </div>
  );
}
