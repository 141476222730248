import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BannerTop from "../BannerTop";
import logo from "../../assets/img/logo.svg";
import Input from "../Input/Input";
import iconUser from "../../assets/img/icons/icon-user.svg";
import iconLock from "../../assets/img/icons/icon-lock.svg";
import iconIdCard from "../../assets/img/icons/icon-idCard.svg";
import iconWhatsapp from "../../assets/img/icons/icon-whatsapp.svg";
import iconMail from "../../assets/img/icons/icon-mail.svg";
import { MyCheckbox } from "../Input/Checkbox";
import NextBtn from "../Buttons/NextBtn";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import url from "../../functions/url";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { Loading } from "../Loading";

export default function Register() {
  const auth = getAuth();
  const Navigate = useNavigate();
  const { signup, user } = useAuth();
  const [ isLoading, setIsLoading ] = useState(false)
  const MySwal = withReactContent(Swal);
  const [errors, setErrors] = useState({
    dni: "",
    name: "",
    surname: "",
    mail: "",
    rol: "client",
    password: "",
    telefonoWhatsapp: "",
    aceptaterminos: false,
  });


  const [input, setInput] = useState({
    dni: "",
    name: "",
    surname: "",
    mail: "",
    rol: "client",
    password: "",
    confirmpassword: "",
    telefonoWhatsapp: "",
    modal: false,
    aceptaterminos: false,
  });

  function aceptaTerminos() {
    if (input.aceptaterminos === true) {
      setInput({
        ...input,
        aceptaterminos: false,
      });
      setErrors(
        validate({
          ...input,
          aceptaterminos: false,
        })
      );
    } else {
      setInput({
        ...input,
        aceptaterminos: true,
      });
      setErrors(
        validate({
          ...input,
          aceptaterminos: true,
        })
      );
    }
  }

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...input,
        [e.target.name]: e.target.value,
      })
    );
  }
  useEffect(() => {
    if (user && !user?.emailVerified) Navigate("/checkmail");
  }, []);

  async function verificar() {
    try {
      const response = await sendEmailVerification(auth.currentUser);
      Navigate("/checkmailafterregister");
    } catch (error) {
      MySwal.fire({
        title: <strong>Ups... hubo un error</strong>,
        html: <p>Error al registar la cuenta</p>,
        icon: "error",
      });
    }
  }

  async function registrar() {
    try {
      await signup(
        input.mail,
        input.password,
        input.rol,
        input.name,
        input.surname
      );
      const postUser = await axios.post(`${url}/users`, {
        dni: input.dni,
        name: input.name,
        surname: input.surname,
        mail: input.mail,
        whatsapp: input.telefonoWhatsapp,
      });
      await verificar();
      //Navigate("/addchildren");
    } catch (error) {
      console.log(error);
      let errorCode;
      switch (error.code) {
        case "auth/invalid-email":
          errorCode = "Todos los campos son obligatorios";
          break;
        case "auth/email-already-in-use":
          errorCode = "El email ya esta en uso";
          break;
        case "auth/weak-password":
          errorCode = "La contraseña debe tener al menos 6 caracteres";
          break;
        case "auth/missing-email":
          errorCode = "El campo mail es obligatorio";
          break;
      }
      MySwal.fire({
        title: <strong>Ups... hubo un error</strong>,
        html: <p>{errorCode}. Intente nuevamente</p>,
        icon: "error",
      });
    }
  }

  return (
    <div className="container-fluid d-flex align-items-center flex-column mb-5">
      <BannerTop imgSrc={logo} width={"60%"} title={"¡Registrate ahora!"} />

      <div className="mt-3">
        {/* Nombre */}
        <div className="mb-3">
          <Input
            name="name"
            label="Nombre"
            type="text"
            onChange={(e) => handleChange(e)}
            value={input.name}
            isInputFilled={input.name !== ""}
            error={errors.name}
            icon={iconUser}
          />
        </div>

        {/* Apellido */}
        <div className="mb-3">
          <Input
            name="surname"
            label="Apellido"
            type="text"
            onChange={(e) => handleChange(e)}
            value={input.surname}
            isInputFilled={input.surname !== ""}
            error={errors.surname}
            icon={iconUser}
          />
        </div>

        {/* contraseña */}
        <div className="mb-3">
          <Input
            name="password"
            label="Contraseña"
            type="password"
            onChange={(e) => handleChange(e)}
            value={input.password}
            isInputFilled={input.password !== ""}
            error={errors.password}
            icon={iconLock}
          />
        </div>

        {/* repetir contraseña */}
        <div className="mb-3">
          <Input
            name="confirmpassword"
            label="Repetir contraseña"
            type="password"
            onChange={(e) => handleChange(e)}
            value={input.confirmpassword}
            isInputFilled={input.confirmpassword !== ""}
            error={errors.confirmpassword}
            icon={iconLock}
          />
        </div>

        {/* DNI */}
        <div className="mb-3">
          <Input
            name="dni"
            label="DNI"
            type="text"
            onChange={(e) => handleChange(e)}
            value={input.dni}
            isInputFilled={input.dni !== ""}
            error={errors.dni}
            icon={iconIdCard}
          />
        </div>

        {/* WhatsApp */}
        <div className="mb-3">
          <Input
            name="telefonoWhatsapp"
            label="Número de WhatsApp"
            type="number"
            onChange={(e) => handleChange(e)}
            value={input.telefonoWhatsapp}
            isInputFilled={input.telefonoWhatsapp !== ""}
            error={errors.telefonoWhatsapp}
            icon={iconWhatsapp}
          />
        </div>

        {/* Correo */}
        <div className="mb-4">
          <Input
            name="mail"
            label="E-mail"
            type="email"
            className="input-nombre-detalles"
            onChange={(e) => handleChange(e)}
            value={input.mail}
            isInputFilled={input.mail !== ""}
            icon={iconMail}
            error={errors.mail}
          />
        </div>

        {/* Términos y condiciones */}
        <div className="d-flex align-items-center mb-1">
          <MyCheckbox
            id="aceptaTerminos"
            name="aceptaterminos"
            onChange={(e) => aceptaTerminos(e)}
            checked={input.aceptaterminos}
            error={errors.aceptaterminos}
          />
          <label
            for="aceptaTerminos"
            className="form-label gray small order-2 mb-0"
          >
            Acepto las condiciones y
            <a
              href=""
              onClick={() => setInput({ ...input, modal: true })}
              className="gray text-decoration-none"
            >
              <span className="fw-semibold">&nbsp;términos de uso</span>
            </a>
          </label>
        </div>

        <div className="mt-3">
          <NextBtn
            onClick={(e) => registrar(e)}
            type="submit"
            disabled={
              errors.mail ||
              errors.name ||
              errors.surname ||
              errors.telefonoWhatsapp ||
              errors.password ||
              errors.aceptaterminos ||
              errors.dni ||
              errors.confirmpassword ||
              !input.password ||
              !input.confirmpassword ||
              !input.mail ||
              !input.name ||
              !input.surname ||
              !input.telefonoWhatsapp ||
              !input.password ||
              !input.aceptaterminos
                ? true
                : false
            }
            text="Siguiente"
          />
        </div>
      </div>
    </div>
  );
}

//validaciones formulario

function validarCorreo(valor) {
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valor)) {
    return false;
  } else {
    return true;
  }
}

function validate(input) {
  let errors = {};
  if (!input.name) {
    errors.name = "Este campo es requerido";
  } else if (!input.surname) {
    errors.surname = "Este campo es requerido";
  } else if (!input.password) {
    errors.password = "Este campo es requerido";
  } else if (input.password.length < 6) {
    errors.password = "La contraseña debe ser de al menos seis caracteres ";
  } else if (input.password !== input.confirmpassword) {
    errors.confirmpassword = "Las contraseñas no coinciden ";
  } else if (!input.dni) {
    errors.dni = "Este campo es requerido";
  } else if (!input.telefonoWhatsapp) {
    errors.telefonoWhatsapp = "Este campo es requerido";
  } else if (!input.mail) {
    errors.mail = "Este campo es requerido";
  } else if (validarCorreo(input.mail)) {
    errors.mail = "Debe ingresar un email válido";
  } else if (input.aceptaterminos === false) {
    errors.aceptaterminos = "Debe aceptar los términos y condiciones";
  }
  return errors;
}
