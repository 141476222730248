import { InputBase, InputLabel, styled } from "@mui/material"
import style from './Input.module.scss'
import { useEffect, useState } from "react";

const BootstrapInput = styled(InputBase)(({ theme, isCompleted }) => ({
  'label + &': {
    marginTop: theme.spacing(0),
  },
  '& .MuiInputBase-input': {
    color: 'rgba(67, 67, 67, .8)',
    fontWeight: 500,
    borderRadius: 8,
    position: 'relative',
    backgroundColor: 'var(--secondary)',
    borderColor: 'var(--primary-light)',
    border: isCompleted && '2px solid var(--primary)',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      border: '2px solid var(--primary)',
      color: 'rgba(67, 67, 67, 1)'
    }
  },
}));


const Input = ({name, label, type, className, onChange, isInputFilled, icon, error, value}) => {
  const [iconOpacity, setIconOpacity] = useState('.5')

  return (
    <div className="d-flex flex-column position-relative">
      <InputLabel shrink htmlFor="email-input" className={style.label}>
        {label}
      </InputLabel>
      <BootstrapInput 
        name={name}
        type={type}
        className={`${className} ${style.input} ${isInputFilled && 'completed'}`}
        onChange={onChange}
        isCompleted = {isInputFilled}
        onFocus={() => setIconOpacity(1)}
        onBlur={() => !isInputFilled && setIconOpacity(.5)}
        value={value}
      />
      {value?.length <= 25 && <img src={icon} className={style.inputIcon} style={{opacity: iconOpacity}}  />}

      {error && (
        <small style={{ color: "var(--bs-danger)" }} className="mb-0">*{error}</small>
      )}
    </div>
  )
}

export default Input