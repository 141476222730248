import style from './Navbar.module.scss'
import { Container, Navbar } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { NavCollapse } from './NavCollapse';
import { UserInitials } from './UserInitials';
import Logo from './Logo';
import { useLocation } from 'react-router-dom';
import NavbarToggle from './NavbarToggle';


const NavbarLogged = ({user}) => {
  const location=useLocation()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    document.querySelector('body').style.overflowY = isOpen ? 'hidden' : 'scroll';
    
    return () => {
      document.querySelector('body').style.overflowY = 'scroll';
    };
  }, [isOpen]);


  return (
    <header>
      <Navbar expand="lg" className={`py-0 pb-1 ${style.navbar}`}>
        <Container className={`py-1 ${style.container}`}>
          <div>
            { location.pathname === '/profile' ? 
              <Logo isOpen={isOpen} className={'p-3'} /> 
              : 
              <UserInitials name={user?.name} surname={user?.surname} isOpen={isOpen} />
            }
          </div>
          <NavbarToggle isOpen={isOpen} setIsOpen={setIsOpen} />
          <NavCollapse user={user} />
        </Container>
      </Navbar>
    </header>
  )
}

export default NavbarLogged

