import React from 'react'
import whatsapp from '../../assets/img/icons/whatsapp-icon.svg'
import whatsappWhite from '../../assets/img/icons/whatsapp-icon-white.svg'

const BtnWhatsapp = ({variant}) => {
  return (
    <a href='https://wa.link/d99irf' target="_blank" className={`btn btn-${variant} w-100 py-3 fw-medium my-3`}>
      <img src={variant === 'primary' ? whatsappWhite : whatsapp} className='me-3' />
      Ir a Whatsapp
    </a>
  )
}

export default BtnWhatsapp
