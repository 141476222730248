import iconHome from '../../../assets/img/icons/icon-home.svg'
import iconInfo from '../../../assets/img/icons/icon-info.svg'
import iconList from '../../../assets/img/icons/icon-list.svg'
import { NavItem } from '../NavItem'

const NavListAdmin = () => {
	const setScroll = () => {
		const current = document.body.style.overflowY
		document.body.style.overflowY = current === 'scroll' ? 'hidden' : 'scroll'
	}
	return (
		<div>
			<NavItem icon={iconList} linkText={'Panel'} route="/admin" onClick={() => setScroll()} />
			<NavItem icon={iconHome} linkText={'Inicio'} route="/weeklymenu" onClick={() => setScroll()} />
			{/* <NavItem icon={iconInfo} linkText={'Información útil'} route="/termsAndConditions" onClick={() => setScroll()} /> */}
		</div>
	)
}

export default NavListAdmin
