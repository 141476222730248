import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '../Divider';

const MyAccordion = ({panel, expanded, handleChange, title, text}) => {
  
  return (
    <Accordion expanded={expanded === panel} onChange={handleChange} className='my-0' style={{boxShadow: 'none'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span className='fs-15 fw-medium' style={{color: 'var(--font-color)'}}>{title}</span>
      </AccordionSummary>
      <div className='px-3'>
        <Divider width={1} className={'my-0'} />
      </div>
      <AccordionDetails>
        <Typography>
          {text}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default MyAccordion