import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const BtnOption = ({className, text, onClick}) => {
  return (
    <button className={`btn ${className} w-100 py-3 ps-3 d-flex justify-content-between`} onClick={onClick}>
      {text}
      <KeyboardArrowRightIcon className='ms-2' />
    </button>
  )
}

export default BtnOption