// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

const firebaseConfig = {
  apiKey: "AIzaSyA2IIRv2EjiNyeYnkos7evdJ-RhD93YmGM",
  authDomain: "espanol-2905.firebaseapp.com",
  projectId: "espanol-2905",
  storageBucket: "espanol-2905.appspot.com",
  messagingSenderId: "484298999096",
  appId: "1:484298999096:web:359d2eca6871f12d9a2dcd",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export async function uploadFile(file) {
  const storageRef = ref(storage, uuidv4());
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
}