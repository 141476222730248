import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useAuth } from "../../context/AuthContext";
import { app } from "../../firebase";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import { Box } from "@mui/material";
import Input from "../Input/Input";
import iconMail from "../../assets/img/icons/icon-mail.svg";
import iconEye from "../../assets/img/icons/icon-eye.svg";
import BannerTop from "../BannerTop";
import TextWithLines from "../Text/TextWithLines";
import NextBtn from "../Buttons/NextBtn";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { getAllOfUser } from "../../actions/index";
import {Loading} from '../Loading'

export default function Home() {
  const Dispatch = useDispatch();
  const firestore = getFirestore(app);
  const { login, user, loading, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const userData = useSelector((state) => state?.allDataOfUser);

  async function getUser() {
    if (user) {
      if (!(userData?.length > 0)) { 
        Dispatch(getAllOfUser(user.email)); 
      }
    }
  } 

  useEffect(() => {
    if (userData?.typeUserId === 1 && user) {
      Navigate("superadmin");
    } else if (userData?.typeUserId === 2 && user) {
      Navigate("/admin");
    } else if (user && !loading) {
      if (!user?.emailVerified) {
        Navigate("/checkmail");
      } else if (userData?.children?.length > 0) {
        Navigate("/weeklymenuorder");
      } else {
        Navigate("/addchildren");
      }
    }
  }, [userData]);

  useEffect(() => {
    getUser();
  }, [user]);

  const getRol = async (uid) => {
    const docuRef = doc(firestore, `usuarios/${uid}`);
    const docuCifrada = await getDoc(docuRef);
    if (docuCifrada.data()) {
      const infoFinal = docuCifrada.data().rol;
      return infoFinal;
    } else {
      return 4;
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true)
      if (user) await logout();
      let r = await login(input.email, input.password);
      let r_uid = await r.user.uid;
      let rol = await getRol(r_uid);
    } catch (error) {
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/wrong-password"
      ) {
        MySwal.fire({
          title: (
            <h3 className="fw-semibold mb-0">
              Usuario o contraseña incorrectos.
            </h3>
          ),
          html: (
            <p className="mb-0 small">
              Intentelo nuevamente, <br />
              por favor
            </p>
          ),
          icon: "error",
          confirmButtonColor: "var(--primary)",
        });
      }
    } finally {
      setIsLoading(false)
    }
  }

  const [input, setInput] = useState({
    email: "",
    password: "",
    modal: false,
  });

  const [inputEnviar, setInputEnviar] = useState({
    email: "",
    password: "",
  });

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setInputEnviar({
      ...inputEnviar,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...input,
        [e.target.name]: e.target.value,
      })
    );
  }

  if(isLoading) return <Loading />

  return (
    <>
      {/* {!loading && !loadingDb ? ( */}
      <div className={`${window.innerWidth > 576 ? "containerLogin" : "container"}`}>
        <BannerTop imgSrc={logo} width={window.innerWidth > 576 ? "30%" : "80%"} title={"Bienvenido"} />
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e)}
          className="px-3 mt-4"
        >
          <div className="mb-4">
            <Input
              name="email"
              label="E-mail"
              type="email"
              className="input-nombre-detalles"
              onChange={(e) => handleChange(e)}
              value={input.email}
              isInputFilled={input.email !== ""}
              icon={iconMail}
              error={errors.email}
            />
          </div>
          <div>
            <Input
              name="password"
              type="password"
              label="Contraseña"
              className="input-nombre-detalles"
              onChange={(e) => handleChange(e)}
              value={input.password}
              isInputFilled={input.password !== ""}
              icon={iconEye}
              error={errors.password}
            />
          </div>
          <div className="text-center my-3">
            <a className="mt-3 text-decoration-none" href="/resetpassword">
              <small className="gray fw-medium">
                ¿Olvidaste tu contraseña?
              </small>
            </a>
          </div>

          <button
            type="submit"
            disabled={
              errors.email || errors.password || !input.email || !input.password
                ? true
                : false
            }
            className="btn btn-primary mt-1 w-100 py-3"
          >
            Acceder
          </button>
        </Box>

        <div className="mt-3 mb-5 text-center">
          <TextWithLines text={"¿Aún no tienes tu cuenta creada?"} />
          <div className="px-3 mt-2">
            <NextBtn
              text="Create una cuenta"
              onClick={() => Navigate("/register")}
              type="button"
            />
          </div>
        </div>
      </div>
    </>
  );
}

function validate(input) {
  let errors = {};
  if (!input.email) {
    errors.email = "Este campo es requerido";
  } else if (!input.password) {
    errors.password = "Este campo es requerido";
  }
  return errors;
}
