import axios from "axios";
import { useEffect, useState } from "react";
import url from "../../functions/url";
import iconEdit from "../../assets/img/icons/icon-edit.svg";
import Input from "../Input/Input";
import MySelect from "../Input/Select";
import { capitalizeFirstLetter } from "../../functions/utils";


// la foto del menu es un scream. hay que cambiarla luego
export default function UpdateChild({ key, id, name, grade, commission, submitBtnClicked }) {
  const [input, setInput] = useState({
    name: "",
    grade: "",
    commission: "", 
  });


  useEffect(() => {
    setInput({
      ...input,
      name: name,
      grade: capitalizeFirstLetter(grade.description),
      commission: commission.description,
    });
  }, []);

  function handleChange(e) {
    if(e.target.name == "grade"){
      let division = e.target.value === "Jardín" ? [ "Sala Roja", "Sala Azul", "Sala Blanca", "Sala Naranja", "Sala Celeste", "Sala Amarilla" ] : ["División A", "División B", "División C", "División D"];  
      setArrayDivision(division);
    }
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  const arrayGrado = [
    "Jardín",
    "1º grado",
    "2º grado",
    "3º grado",
    "4º grado",
    "5º grado",
    "6º grado",
    "7º grado",
  ];

  const [arrayDivision, setArrayDivision] = useState([
    "División A",
    "División B",
    "División C",
    "División D",
    "Sala Roja",
    "Sala Azul",
    "Sala Blanca",
    "Sala Naranja",
    "Sala Celeste",
    "Sala Amarilla"
  ]);
  
  async function handleChangeGrade() {
    const grado = input.grade;
    await axios.put(
      `${url}/child/grade/${id}`,
      {
        grade: grado === "Jardín" ? "0" : grado.charAt(0),
      }
    );
  }

  async function handleChangeCommission() {
    let comision;
    switch (input.commission) {
      case "División A":
        comision = "A";
        break;
      case "División B":
        comision = "B";
        break;
      case "División C":
        comision = "C";
        break;
      case "División D":
        comision = "D";
        break;
      case "Sala Roja":
        comision = "1";
        break;
      case "Sala Azul": 
        comision = "2";
        break;
      case "Sala Blanca": 
        comision = "3";
        break;
      case "Sala Naranja": 
        comision = "4";
        break;
      case "Sala Celeste":
        comision = "5";
        break;
      case "Sala Amarilla":
        comision = "6";
        break;
      }
    await axios.put(`${url}/child/commission/${id}`, {
      commission: comision,
    }); 
  }

  
  async function handleChild() {
    await axios.put(`${url}/child/name/${id}`, 
    {
      name: input.name,
    });
    handleChangeGrade()
    handleChangeCommission()
  }

  useEffect(() => {
    submitBtnClicked && handleChild()
  }, [submitBtnClicked])
  
  return (
    <div>
      <div className="mb-3">
        <Input
          name="name"
          label="Nombre y Apellido"
          type="text"
          onChange={(e) => handleChange(e)}
          value={input.name}
          isInputFilled={input.name !== ""}
          icon={iconEdit}
        />
      </div>
      <div className="mb-3">
        <MySelect
          name="grade"
          label={"Grado Escolar"}
          onChange={(e) => handleChange(e)}
          defaultValue={input.grade}
          options={arrayGrado}
        />
      </div>
      <div className="mb-3">
        <MySelect
          name="commission"
          label={"Comisión"}
          onChange={(e) => handleChange(e)}
          defaultValue={input.commission}
          options={arrayDivision}
        />
      </div>
    </div>
  );
}
