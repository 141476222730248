import style from './Navbar.module.scss'
import { Nav, Navbar } from "react-bootstrap"
import CERLogo from '../../assets/img/logo.svg'
import Divider from "../Divider";
import { NavList } from './NavList';
import NavListAdmin from './Admin/NavList';
import LogoutBtn from './Logout';
import { UserName } from './UserName';

export const NavCollapse = ({user, isAdmin}) => {
	return (
		<Navbar.Collapse className={style.navbarCollapse} id="navbar-collapse">
			<Nav className="me-auto h-100 justify-content-between overflow-scroll">
				<div>
					<div className='p-4 mt-2 d-flex justify-content-center align-items-center'>
						<img src={CERLogo} width={'80%'} />
					</div>
					<div className='px-4'>
						<div>
							<UserName user={user} isAdmin={isAdmin} />
							<Divider width={1} />
							{!isAdmin ? <NavList /> : <NavListAdmin />}
						</div>
					</div>
				</div>
				
				<div className='d-flex flex-column align-items-center pt-5'>
					<LogoutBtn />
					<small className="gray fw-medium mb-4">Colegio Español de Rosario</small>
				</div>
			</Nav>
		</Navbar.Collapse>
	)
}