import { Button, Form, Modal } from "react-bootstrap";
import Input from "../../Input/Input";
import { useEffect, useState } from "react";
import axios from "axios";
import url from "../../../functions/url";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Divider from "../../Divider";

const ModalChangeMenu = ({
  setMenus,
  handleSubmitMenu,
  handleMenus,
  handleClose,
  show,
}) => {
  let [priceVianda, setPriceVianda] = useState(0);
  let [priceMenu, setPriceMenu] = useState(0);
  const MySwal = withReactContent(Swal);



  async function getWeekInfo() {
    let propertiesApi = await axios.get(`${url}/propertiesorderrouter`);
    propertiesApi = propertiesApi.data;
    if (propertiesApi?.length > 0) {
      const arrayMenuPriceFiltered = propertiesApi?.filter(
        (el) => el?.type.replace(/£/g, "ú") === "Menú semanal"
      );
      const arrayViandaPriceFiltered = propertiesApi?.filter(
        (el) => el?.type === "Vianda"
      );
      if (arrayMenuPriceFiltered?.length > 0) {
        setPriceMenu(arrayMenuPriceFiltered[0]?.price);
      }
      if (arrayViandaPriceFiltered?.length > 0) {
        setPriceVianda(arrayViandaPriceFiltered[0]?.price);
      }
    }
  }

  async function fsetPriceMenu() {
    const idPutMenuPrice = 2;
    try {
      if (priceMenu) {
        const put = await axios.put(`${url}/admin/price/${idPutMenuPrice}`, {
          price: priceMenu,
        });
        if (put.status === 200) {
          MySwal.fire({
            title: "¡Precio Menú Actualizado!",
            icon: "success",
            confirmButtonColor: "var(--primary)",
          });
        }
      }
    } catch (error) {
      console.log(error);
      MySwal.fire({
        title: "Hubo un error",
        html: (
          <p className="mb-0">
            Inténtelo de nuevo más tarde,
            <br />
            por favor
          </p>
        ),
        icon: "error",
        confirmButtonColor: "var(--primary)",
      });
    }
  }

  async function fsetPriceVianda() {
    const idPutViandaPrice = 1;
    try {
      if (priceVianda) {
        const put = await axios.put(`${url}/admin/price/${idPutViandaPrice}`, {
          price: priceVianda,
        });
        if (put.status === 200) {
          MySwal.fire({
            title: "¡Precio Vianda Actualizado!",
            icon: "success",
            confirmButtonColor: "var(--primary)",
          });
        }
      }
    } catch (error) {
      console.log(error);
      MySwal.fire({
        title: "Hubo un error",
        html: (
          <p className="mb-0">
            Inténtelo de nuevo más tarde,
            <br />
            por favor
          </p>
        ),
        icon: "error",
        confirmButtonColor: "var(--primary)",
      });
    }
  }

  useEffect(() => {
    getWeekInfo();
  }, []);

  async function getWeekInfo() {
    let propertiesApi = await axios.get(`${url}/propertiesorderrouter`);
    propertiesApi = propertiesApi.data;
    if (propertiesApi?.length > 0) {
      const arrayMenuPriceFiltered = propertiesApi?.filter(
        (el) => el?.type.replace(/£/g, "ú") === "Menú semanal"
      );
      const arrayViandaPriceFiltered = propertiesApi?.filter(
        (el) => el?.type === "Vianda"
      );
      if (arrayMenuPriceFiltered?.length > 0) {
        setPriceMenu(arrayMenuPriceFiltered[0]?.price);
      }
      if (arrayViandaPriceFiltered?.length > 0) {
        setPriceVianda(arrayViandaPriceFiltered[0]?.price);
      }
    }
  }

  function handleChangeMenuPrice(e) {
    setPriceMenu(e.target.value)
  }

  function handleChangeViandaPrice(e) {
    setPriceVianda(e.target.value)
  }



  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fw-semibold">Actualizar menú</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmitMenu}>
      <Modal.Body>
        <Form.Group controlId="inputJardin" className="mb-3">
          <Form.Label className='primary fw-semibold'>Menú Jardín</Form.Label>
          <Form.Control type="file" onChange={handleMenus} />
        </Form.Group>
        <Form.Group controlId="inputPrimaria" className="mb-3">
          <Form.Label className='primary fw-semibold'>Menú Primaria</Form.Label>
          <Form.Control type="file" onChange={handleMenus} />
        </Form.Group>
        <Divider width={1} />
        <div className="mb-3">
          <Input
            name="menuPrice"
            label="Precio Menú"
            type="number"
            onChange={(e) => handleChangeMenuPrice(e)}
            value={priceMenu}
          />
          <div className="d-flex justify-content-end">
            <Button
              variant="secondary"
              onClick={() => fsetPriceMenu()}
              className="mt-2"
            >
              Guardar precio Menú
            </Button>
          </div>
        </div>

        <div className="mb-3">
          <Input
            name="viandaPrice"
            label="Precio Vianda"
            type="number"
            onChange={(e) => handleChangeViandaPrice(e)}
            value={priceVianda}
          />
          <div className="d-flex justify-content-end">
            <Button
              variant="secondary"
              onClick={() => fsetPriceVianda()}
              className="mt-2"
            >
              Guardar precio Vianda
            </Button>
          </div>
        </div>
        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" type='submit' onClick={handleClose}>
          Guardar
        </Button>
      </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalChangeMenu;
