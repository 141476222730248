const TextWithLines = ({text, styling}) => {
	const style = {
		display: 'flex',
		...styling
	}
	const hrStyle = {
		width: '12%',
   		border: '1px solid var(--gray-light)',
		opacity: .3,
	}
	return(
		<div className="align-items-center justify-content-center" style={style}>
			<hr style={hrStyle} />
			<small className="light-gray fw-medium" style={{margin: '0 10px'}}>{text}</small>
			<hr style={hrStyle} />
		</div>
	)
}

export default TextWithLines