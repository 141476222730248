import MySelect from "../Input/Select";
import iconUser from "../../assets/img/icons/icon-user.svg";
import Input from "../Input/Input";
import { useEffect, useState } from "react";

const Child = ({
  id,
  errorsGlobal,
  updateArrayChildren,
}) => {
  const [errors, setErrors] = useState({
    name: "",
    grade: "",
    commission: "",
  });

  const [input, setInput] = useState({
    name: "",
    grade: "",
    commission: "",
  });

  const arrayGrado = [
    "Jardín",
    "1º grado",
    "2º grado",
    "3º grado",
    "4º grado",
    "5º grado",
    "6º grado",
    "7º grado",
  ];

  const [arrayDivision, setArrayDivision] = useState([
    "División A",
    "División B",
    "División C",
    "División D",
    "Sala Roja",
    "Sala Azul",
    "Sala Blanca",
    "Sala Naranja",
    "Sala Celeste",
    "Sala Amarilla"
  ]);
  useEffect(() => {
    errorsGlobal(errors, input , id);
  });

  useEffect(() => {
    let comision;
    switch (input.commission) {
      case "División A":
        comision = "A";
        break;
      case "División B":
        comision = "B";
        break;
      case "División C":
        comision = "C";
        break;
      case "División D":
        comision = "D";
        break;
      case "Sala Roja":
        comision = "1";
        break;
      case "Sala Azul": 
        comision = "2";
        break;
      case "Sala Blanca": 
        comision = "3";
        break;
      case "Sala Naranja": 
        comision = "4";
        break;
      case "Sala Celeste":
        comision = "5";
        break;
      case "Sala Amarilla":
        comision = "6";
        break;
      }
    updateArrayChildren(input.name, input.grade, comision, id);
  }, [input]);

  function handleChange(e) {
    if(e.target.name == "grade"){
      let division = e.target.value === "Jardín" ? [ "Sala Roja", "Sala Azul", "Sala Blanca", "Sala Naranja", "Sala Celeste", "Sala Amarilla" ] : ["División A", "División B", "División C", "División D"];  
      setArrayDivision(division);
    }
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...input,
        [e.target.name]: e.target.value,
      })
    );

    errorsGlobal(errors, input, id);
  }

  return (
    <>
      <div className="mb-3">
        <Input
          name="name"
          label="Nombre y apellido"
          type="text"
          onChange={(e) => handleChange(e)}
          value={input.name}
          isInputFilled={input.name !== ""}
          error={errors.name}
          icon={iconUser}
        />
      </div>
      <div className="mb-3">
        <MySelect
          name="grade"
          label={"Grado escolar"}
          onChange={(e) => handleChange(e)}
          value={input.grade}
          options={arrayGrado}
          error={errors.grade}
        />
      </div>
      <div className="mb-3">
        <MySelect
          name="commission"
          label={"División"}
          onChange={(e) => handleChange(e)}
          value={input.commission}
          options={arrayDivision}
          error={errors.commission}
        />
      </div>
    </>
  );
};

export default Child;

function validate(input) {
  let errors = {};
  if (!input.name) {
    errors.name = "Este campo es requerido";
  } else if (!input.grade) {
    errors.grade = "Este campo es requerido";
  } else if (!input.commission) {
    errors.commission = "Este campo es requerido";
  }
  return errors;
}
