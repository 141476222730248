import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import TitleSmall from "../Text/TitleSmall";
import MainContainer from "../Container/MainContainer";
import LayoutLogged from "../Layout/LayoutLogged";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "../Divider";
import MySelect from "../Input/Select";
import bgImg from "../../assets/img/orders-img.svg";
import WeekSummaryMyOrders from "./WeekSummaryMyOrders";
import Week from "../Week/Week";
import url from "../../functions/url.js";
import BtnWhatsapp from "../Buttons/BtnWhatsapp";
import { Loading } from "../Loading";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const { parse, isAfter, parseISO } = require("date-fns");

export default function MyOrders() {
  const { user, loading } = useAuth();
  const [peticiones, setPeticiones] = useState(false);
  const [userData, setUserData] = useState([]);
  let [selectedWeek, setSelectedWeek] = useState("");
  let [completedWeek, setCompletedWeek] = useState([])

  const Navigate = useNavigate();
  console.log("url en myorders", url)
  
  useEffect(() => {
    if (userData?.rol === "superadmin") {
      Navigate("superadmin");
    } else if (userData?.rol === "admin") {
      Navigate("/admin");
    } else if (user && !loading) {
      if (!user.emailVerified) {
        Navigate("/checkmail");
      }
    } else if (!user && !loading) {
      Navigate("/");
    }
  }, [userData]);


let weekCompleted=[]
  async function getUser(mail) {
    try {
      const userDataApi = await axios.get(`${url}/users/myorders/${mail}`);
      setUserData(userDataApi.data);
      setPeticiones(true);
    } catch (error) {
      console.log("error");
    }
  }


  let arrayWeeks = [];

  for (let i = 0; i < userData.arrayWeek?.length; i++) {
    arrayWeeks.push(userData.arrayWeek[i].week);
    weekCompleted.push(userData.arrayWeek[i]);

  }


  let today=""
  let arrayWeekFiltered=[]
  const handleChange = (e) => {
    setSelectedWeek(e.target.value);
   // selectedWeek={selectedWeek}
    arrayWeekFiltered=weekCompleted.filter((el) => el.week===e.target.value)
    setCompletedWeek(arrayWeekFiltered ? arrayWeekFiltered[0] : null)
  };

  function maxDateFilter() {
    const maxDateFiltered = userData.arrayWeek.filter(
      (el) => el.week === userData.maxFecha
    );

    if (maxDateFiltered.length > 0) {
      setSelectedWeek(maxDateFiltered[0].week);
    }
  } 

  if (!peticiones && user) getUser(user.email);
  if (loading) return <Loading />; 

  console.log("completedWeek", completedWeek)
  return (
    <LayoutLogged>
      <MainContainer paddingX={4} background={true}>
        <TitleSmall
          divClassName="mt-3"
          title={"Mis pedidos"}
          subtitle={
            <span>
              Última semana de pedido realizado: &nbsp;
              <span id="week-start">
                {userData.maxFecha?.split(" ").slice(1).join(" ")}
              </span>
            </span>
          }
        />
        <button
          className={`btn btn-link-primary ps-0`}
          onClick={() => maxDateFilter()}
        >
          <ArrowForwardIcon className="me-2" fontSize={"inherit"} />
          <span className="small">Ver mi último pedido</span>
        </button>
        <Divider width={1} className={"mt-1 mb-3"} />

        <div>
          <p className="light-gray smaller mb-1">
            Seleccioná la semana que quieras visualizar
          </p>
          <MySelect
            name={"selectedWeek"}
            value={selectedWeek}
            options={arrayWeeks}
            onChange={(e) => handleChange(e)}
          />
        </div>

      <div className="d-flex flex-column justify-content-center align-items-center py-2">
        {selectedWeek ? 
          <div className="w-100">
            <WeekSummaryMyOrders today={parseISO(completedWeek.mondayWeek)} arrayWeekFiltered={completedWeek}/>
            <Divider width={1}  />
            <p className="gray fw-medium text-center mt-4">
              Si tienes algún tipo de consulta sobre los pedidos ó menues disponibles por favor no dudes en contactanos
            </p>
            <BtnWhatsapp variant={'primary'} />
          </div>
            : 
          <img src={bgImg} width={'120%'} className="py-5" /> 
        }
      </div>
      </MainContainer>
    </LayoutLogged>
  );
}
