import React, { useState } from 'react'
import LayoutLogged from '../Layout/LayoutLogged'
import MainContainer from '../Container/MainContainer'
import MyAccordion from '../Accordion/Accordion'



const TermsAndConditions = () => {
  const [ expanded, setExpanded ] = useState('panel1')
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <LayoutLogged>
      <MainContainer paddingX={3}>
        <MyAccordion 
          expanded={expanded} panel={'panel1'} handleChange={handleChange('panel1')} title={'Términos y condiciones'}
          text={
            <div>
              <p className='smaller gray'>
                última actualización: 24/04/2023
              </p>
              <p className='smaller gray mb-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ut lectus in massa fermentum lobortis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas sagittis, ante at condimentum blandit, nisl mi vulputate libero, non pulvinar augue sapien at purus. Praesent placerat nisi pharetra nunc tempus placerat. Vivamus ornare neque arcu, ac pellentesque ipsum laoreet a. Vestibulum pretium iaculis interdum.</p>
              <p className='smaller gray mb-2'>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nullam sed tristique justo, in rutrum eros. Maecenas faucibus sapien et sapien consectetur consectetur. Curabitur mi risus, faucibus at cursus id, lacinia eget libero. Vivamus pretium mollis ex, sit amet interdum tortor viverra eu. Curabitur arcu sem, fermentum in nisl in, suscipit venenatis ipsum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In hac habitasse platea dictumst. Praesent molestie quis leo in sagittis. Maecenas ac ante nec massa fringilla molestie sed eget risus. </p>
            </div>
          }
        />
        <MyAccordion 
          expanded={expanded} panel={'panel2'} handleChange={handleChange('panel2')} title={'Preparación del menú semanal'}
          text={
            <div>
              <p className='smaller gray mb-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ut lectus in massa fermentum lobortis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas sagittis, ante at condimentum blandit, nisl mi vulputate libero, non pulvinar augue sapien at purus. Praesent placerat nisi pharetra nunc tempus placerat. Vivamus ornare neque arcu, ac pellentesque ipsum laoreet a. Vestibulum pretium iaculis interdum.</p>
            </div>
          }
        />
        <MyAccordion 
          expanded={expanded} panel={'panel3'} handleChange={handleChange('panel3')} title={'Métodos de pago del servicio'}
          text={
            <div>
              <p className='smaller gray mb-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ut lectus in massa fermentum lobortis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas sagittis, ante at condimentum blandit, nisl mi vulputate libero, non pulvinar augue sapien at purus. Praesent placerat nisi pharetra nunc tempus placerat. Vivamus ornare neque arcu, ac pellentesque ipsum laoreet a. Vestibulum pretium iaculis interdum.</p>
            </div>
          }
        />
      </MainContainer>
    </LayoutLogged>
  )
}

export default TermsAndConditions