import Lottie from "lottie-react";
import loadingAnimation from '../../lotties/loading-animation.json';

export const LoadingMenus = () => {
	return (
		<div className="d-flex flex-column align-items-center my-3">
			<h3 className="fw-bold text-center mt-3">
				Cargando menúes...
			</h3>
			<Lottie animationData={loadingAnimation} loop={true} />
		</div>
	)
}