import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const ModalMenu = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton /> */}
      <Modal.Body>
        <img src={props.img} />
      </Modal.Body>
    </Modal>
  )
}

export default ModalMenu