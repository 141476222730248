import ElementAdminGrade from "../ElementAdminGrade/ElementAdminGrade";
import DownloadBtn from "../DownloadExcel";
import { useEffect } from "react";

export default function ElementAdminDate({ dateFiltered, date, grades, dataUsersFiltered }) {

  const formatDate=date.split("-").reverse().join('/')


  const arrayGrado = [
    "Jardín",
    "1º grado",
    "2º grado",
    "3º grado",
    "4º grado",
    "5º grado",
    "6º grado",
    "7º grado",
  ];

  useEffect(() =>{
    dateFiltered(date)
  }, [date])
   
  
  const excelData = dataUsersFiltered;
  return (
    <div className="row mt-2">
      <div className="px-2 mb-2">
        <div className="title-secondary">
          <h6 className="fw-semibold mb-0">{formatDate}</h6>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <DownloadBtn
            excelData={excelData}
            fileName={`pedidos-${formatDate}`}
          />
        </div>
      </div>
      {grades?.map((el) => {
      if(el.commissions[0].data.length){
        return (
          <div className="mt-2">
            <h5 className="fw-semibold mb-1">{arrayGrado[el.grade]}</h5>
            <span className="primary fw-semibold">
             
              <span className="fw-bold">
                {el.commissions ? el.commissions[0].description : null}
              </span>
            </span>
            <div className="mt-2">
              <ElementAdminGrade commissions={el.commissions} />
            </div>
          </div>
        );
        }
      })}
    </div>
  );
}
