import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../../functions/url";
export default function SuperAdmin() {
  const Navigate = useNavigate();
  const { user, loading, logout } = useAuth();
  if (user) getUser(user.email);

  async function getUser(mail) {
    const userData = await axios.get(
      `${url}/users/${mail}`
    );

    if (userData.data.rol === "superadmin") {
      Navigate("/superadmin");
    } else if (userData.data.rol === "admin") {
      Navigate("/admin");
    } else if (userData.data.children?.length > 0) {
      Navigate("/weeklymenu");
    } else {
      Navigate("/addchildren");
    }
  }

  if (!loading && !user) {
    Navigate("/");
  }
  if (loading) return <p>...loading</p>;
  return (
    <div>
      Superadmin
      <button onClick={async () => await logout()}>Logout</button>
    </div>
  );
}
