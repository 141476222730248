import { useEffect, useState } from "react";
import Child from "./Child";
import TextWithLines from "../Text/TextWithLines";
import AddIcon from '@mui/icons-material/Add';
import { display } from "@mui/system";


const ChildList = ({arrayChildren, setArrayChildren, globalError, setGlobalError}) => {
	const [numChildren, setNumChildren] = useState([]);
	const [send, ] = useState(false);
  const [children, setChildren] = useState([]);
	let itemInErrorArray = [];
  let arrayChildrenSendToBack = [];

	useEffect(() => {
		console.log(numChildren.length)
	},[])

	const agregarInput = () => {
    const nuevoInput = {
      id: numChildren.length + 1,
    };
    setNumChildren([...numChildren, nuevoInput]);
  };

	function updateArrayChildren(name, grade, commission, id) {
    if (id) {
      let childrenFiltered = arrayChildren.find((item) => item.id === id);
      if (childrenFiltered) {
        arrayChildrenSendToBack = arrayChildren.map((item) =>
          item.id === id
            ? {
                ...item,
                name: name,
                grade: grade,
                commission: commission,
              }
            : item
        );
        setArrayChildren(arrayChildrenSendToBack);
      } else {

        setArrayChildren([
          ...arrayChildren,
          {
            id: id,
            name: name,
            grade: name,
            commission: name,
          },
        ]);
      }
    }
  }

	function errorsGlobal(errors, input, id) {
    //<----------------------Manejo de erores global-------------------->
    if (
      errors.name ||
      errors.grade ||
      errors.commission ||
      !input.name ||
      !input.grade ||
      !input.commission
    ) {
      // se busca el id en el arreglo, si no se encuentro se agrega.
      itemInErrorArray = globalError.find((item) => item === id);
      if (!itemInErrorArray) {
        setGlobalError([...globalError, id]);
      }
    } else {
      // se busca el id en el arreglo, si se encuentra se saca.
      itemInErrorArray = globalError.find((item) => item === id);
      if (itemInErrorArray) {
        setGlobalError(globalError.filter((el) => el !== id));
      }
    }
    //<---------------------- Fin Manejo de erores global-------------------->

    //<---------------------- Manejo del arreglo de children para luego mandar al backend-------------------->

    //Para juntar los children que hay que mandar al backend, ingresamos en un arreglo
    // los datos de todos los children.

    //<---------------------- Fin Manejo del arreglo de children para luego mandar al backend--------------------->
  }
	
	return (
		<>
			<div>
				{numChildren.map((child) => (
					<div>
						<Child
							key={child.id}
							id={child.id}
							errorsGlobal={errorsGlobal}
							globalError={globalError}
							send={send}
							/*               sendToBackend={sendToBackend} */
							children={children}
							setChildren={setChildren}
							updateArrayChildren={updateArrayChildren}
						/>
						{(numChildren.indexOf(child) !== numChildren.length - 1) && <hr className="my-4" style={{border: '1px solid #2D2D2D', opacity: .3 }} /> }
					</div>
				))}
			</div>

			<TextWithLines text={'¿Querés agregar a otro hijo/a?'} styling={{display: (numChildren.length !== 0) ? 'flex' : 'none'}} />
			<button
				className="btn btn-secondary mt-2 w-100 py-3"
				onClick={() => agregarInput()}
				type="button"
			>
				<AddIcon className="me-2" style={{ fontSize: "1.8rem" }} />
				Agregar <span style={{display: (numChildren.length !== 0) ? 'inline' : 'none'}}>otro</span> hijo/a
			</button>
		</>
	)
}

export default ChildList;