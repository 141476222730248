import style from './Navbar.module.scss'
import { Navbar } from 'react-bootstrap'
import menu from '../../assets/img/icons/menu-eclipse.svg'
import menuClose from '../../assets/img/icons/menu-eclipse-close.svg'

const NavbarToggle = ({isOpen, setIsOpen}) => {
  
  const handleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Navbar.Toggle className={`p-0 border-0 ${style.toggler}`}aria-controls="navbar-collapse" onClick={() => handleOpen()}>
      {isOpen ? <span className='fw-bold me-2' style={{fontSize: '15px'}}>Cerrar Menú</span> : null}
      <img src={isOpen ? menuClose : menu} width={60} />
    </Navbar.Toggle>
  )
}

export default NavbarToggle