import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import PrevBtn from "../Buttons/PrevBtn";
import NextBtn from "../Buttons/NextBtn";
import LayoutLogged from "../Layout/LayoutLogged";
import MainContainer from "../Container/MainContainer";
import url from "../../functions/url";
import WeekSummary from "./WeekSummary";
import { addDays, addWeeks, format, parse, startOfWeek } from "date-fns";
import { getDay } from "date-fns";
import { Loading } from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import { getAllOfUser } from "../../actions/index";

export default function Summary() {
  const Dispatch = useDispatch();
  const Navigate = useNavigate();
  const { user, loading } = useAuth();
  const [isHidden, setIsHidden] = useState(true);
  const [propertiesOrder, setPropertiesOrder] = useState([]);
  const userData = useSelector((state) => state?.allDataOfUser);

  useEffect(() => {
    if (user && !loading) {
      getUser(user.email);
    }
  }, [loading]);

  async function getUser() {
    if (user) {
      if (userData?.length !== 0) {
        Dispatch(getAllOfUser(user.email));
      }
    }
    setIsHidden(false);
  }

  useEffect(() => {
    if (userData?.rol === "superadmin") {
      Navigate("superadmin");
    } else if (userData?.rol === "admin") {
      Navigate("/admin");
    } else if (user && !loading) {
      if (!user.emailVerified) {
        Navigate("/checkmail");
      }
    } else if (!user && !loading) {
      Navigate("/");
    }
  }, [userData]);

  let pesosMenu = 0;
  let pesosVianda = 0;

  let precioMenu = propertiesOrder.filter((el) => el.type === "Menú semanal");
  if (precioMenu.length > 0) {
    pesosMenu = precioMenu[0].price;
  }

  let precioVianda = propertiesOrder.filter((el) => el.type === "Vianda");
  if (precioVianda.length > 0) {
    pesosVianda = precioVianda[0].price;
  }

  const [propertiesOrderWeek, setPropertiesOrderWeek] = useState([]);

  let totalValue = 0;

  if (propertiesOrderWeek?.children) {
    for (let i = 0; i < propertiesOrderWeek?.children?.length; i++) {
      for (let j = 0; j < propertiesOrderWeek.children[i]?.carts?.length; j++) {
        let arrayFilteredCarts = propertiesOrderWeek.children[i]?.carts.filter(
          (el) => el.status === "activo"
        );
        for (let h = 0; h < arrayFilteredCarts[0]?.orders?.length; h++) {
          totalValue =
            totalValue +
            Number(arrayFilteredCarts[0]?.orders[h]?.properties_order.price);
        }
      }
    }
  }

  const [peticiones, setPeticiones] = useState(false);
  const [total, setTotal] = useState({
    contMenu: 0,
    contVianda: 0,
  });
  const [selectedDate, setSelectedDate] = useState("");
  let [update, setUpdate] = useState(false);

  let [childrenInfo, setChildrenInfo] = useState([]);

  let [weekInfo, setWeekInfo] = useState({
    firstDay: "",
    endDay: 5,
  });

  let [countMenu, setCountMenu] = useState([]);
  let [countVianda, setCountVianda] = useState([]);
  let [countTotalVianda, setCountTotalVianda] = useState(0);
  let [countTotalMenu, setCountTotalMenu] = useState(0);

  let [priceMenu, setPriceMenu] = useState(0);
  let [priceVianda, setPriceVianda] = useState(0);

  function countTotalFunction(subTotalMenu, subTotalVianda) {}
  //<.---------- END Hooks statement------------------>

  //<------------------------------Get data--------------------------------->
  let today = new Date();
  const todayFormat = format(today, "dd/MM");

  const mondayNextWeek = startOfWeek(addWeeks(today, 1), {
    weekStartsOn: 1,
  });
  const mondayNextWeekFormated = format(mondayNextWeek, "yyyy-MM-dd");

  const fridayNextWeek = startOfWeek(addWeeks(today, 1), {
    weekStartsOn: 5,
  });

  const friday = startOfWeek(addWeeks(today, 5));

  const options = { weekStartsOn: 1 };

  const monday = startOfWeek(today, options);
  let tomorrowNineAm = addDays(today, 1);
  tomorrowNineAm.setHours(8, 59, 59, 999);
  let todayNineAm = new Date();
  todayNineAm.setHours(8, 59, 59, 999);
  let nDayToday = getDay(today);
  let dayTodayIterable;
  let week;
  let weekInit;
  let weekEnd;

  if ((nDayToday === 5 && todayNineAm < today) || nDayToday === 6) {
    week = mondayNextWeekFormated;
    weekInit = format(mondayNextWeek, "dd/MM");
    weekEnd = format(fridayNextWeek, "dd/MM");
  } else {
    week = monday.toISOString().slice(0, 10);
    weekInit = format(monday, "dd/MM");
    weekEnd = format(friday, "dd/MM");
  }
  let firDay = addDays(week, 1);

  useEffect(() => {
    if (user) userInfo();
  }, [user]);

  useEffect(() => {
    getWeekInfo();
  }, [propertiesOrderWeek.children]);

  let arrayFiltered = [];
  let contadorMenu = 0;
  let contadorVianda = 0;

  async function userInfo() {
    const sendWeek = week;
    const propertiesOrderWeekApi = await axios.post(
      `${url}/order/week/${user.email}`,
      {
        week: sendWeek,
      }
    );

    // aca decia ...propertiesOrder y lo cambie por ...propertiesOrderWeek
    setSelectedDate(sendWeek);
    setPropertiesOrderWeek(propertiesOrderWeekApi.data);

    arrayFiltered = propertiesOrderWeekApi.data.children;
    arrayFiltered = arrayFiltered?.map((el) => {
      return el.carts;
    });
    arrayFiltered = arrayFiltered?.map((el) => {
      return el[0];
    });

    arrayFiltered = arrayFiltered?.filter((el) => el.status === "activo");

    contadorMenu = 0;
    contadorVianda = 0;
    for (let i = 0; i < arrayFiltered?.length; i++) {
      for (let j = 0; j < arrayFiltered[i].orders?.length; j++) {
        if (
          arrayFiltered[i].orders[j].properties_order.type?.replace(
            /£/g,
            "ú"
          ) === "Menú semanal"
        ) {
          contadorMenu++;
        }
        if (
          arrayFiltered[i].orders[j].properties_order.type?.replace(
            /£/g,
            "ú"
          ) === "Vianda"
        ) {
          contadorVianda++;
        }
      }
    }
    setCountTotalMenu(contadorMenu);
    setCountTotalVianda(contadorVianda);
    setUpdate(!update);
    setChildrenInfo(propertiesOrderWeek.data);
    getDays();
  }

  async function getWeekInfo() {
    let propertiesApi = await axios.get(`${url}/propertiesorderrouter`);
    setPropertiesOrder(propertiesApi.data);
    propertiesApi = propertiesApi.data;
    if (propertiesApi.length > 0) {
      let arrayMenuPriceFiltered = propertiesApi?.filter(
        (el) => el?.type.replace(/£/g, "ú") === "Menú semanal"
      );
      let arrayViandaPriceFiltered = propertiesApi?.filter(
        (el) => el?.type === "Vianda"
      );
      if (arrayMenuPriceFiltered.length > 0) {
        setPriceMenu(arrayMenuPriceFiltered[0].price);
      }
      if (arrayViandaPriceFiltered.length > 0) {
        setPriceVianda(arrayViandaPriceFiltered[0].price);
      }
    }
  }

  //<------------------------------------------------------End get data--------------------------------------------------------->

  //<--------------------------------------------------codigo para hacer pruebas-------------------------------------------------->

  // week = parse("2023-06-19", "yyyy-MM-dd", new Date());
  // today = parse("2023-06-24", "yyyy-MM-dd", new Date());
  // today.setHours(8, 26, 59, 999);
  // todayNineAm = parse("2023-06-24", "yyyy-MM-dd", new Date());
  // todayNineAm.setHours(8, 59, 59, 999);
  // tomorrowNineAm = parse("2023-06-25", "yyyy-MM-dd", new Date());
  // tomorrowNineAm.setHours(8, 59, 59, 999);
  // nDayToday = 6;

  //<-------------------------------------FIN codigo para hacer pruebas-------------------------->
  function getDays() {
    let arrayDay = [];
    if (nDayToday === 0) {
      //     setInit(true);
      console.log("es domingo, se puede hacer pedidos para toda la semana");
      //<--------------es domingo, se pueden hacer pedidos para cualquier día de la semana--------->
      dayTodayIterable = monday;
      for (let i = 0; i < 5; i++) {
        let object = {
          day: dayTodayIterable,
          status: true,
        };
        dayTodayIterable = addDays(dayTodayIterable, 1);
        arrayDay.push(object);
      }
      setWeekInfo({
        ...weekInfo,
        firstDay: 1,
        today: today,
        tomorrowNineAm: tomorrowNineAm,
        arrayDays: arrayDay,
      });
    } else if ((nDayToday === 5 && todayNineAm < today) || nDayToday === 6) {
      //<---------------->es viernes después de las 9am o sábado, por lo que se puede pedir recién para la semana que viene--------->
      console.log("se puede pedir solo para la semana que viene");
      dayTodayIterable = mondayNextWeek;

      for (let i = 0; i < 5; i++) {
        let object = {
          day: dayTodayIterable,
          status: true,
        };

        dayTodayIterable = addDays(dayTodayIterable, 1);
        arrayDay.push(object);
      }

      setWeekInfo({
        ...weekInfo,
        firstDay: 1,
        today: today,
        tomorrowNineAm: tomorrowNineAm,
        arrayDays: arrayDay,
      });
    } else if (nDayToday < 6 && todayNineAm < today) {
      // <----------------es dia menor que sábado y son mas de las 9 am por lo que se puede pedir a partir de mañana-------->

      console.log("Se puede pedir a partir de mañana");
      dayTodayIterable = monday;
      let since = nDayToday;
      for (let i = 0; i < since; i++) {
        // estos días se mostrarán pero no se podrán seleccionar
        let object = {
          day: dayTodayIterable,
          status: false,
        };
        dayTodayIterable = addDays(dayTodayIterable, 1);
        arrayDay.push(object);
      }

      since = nDayToday;
      for (let i = since; i < 5; i++) {
        // estos son los días que se tienen en cuenta para mostrarlos en pantalla

        let object = {
          day: dayTodayIterable,
          status: true,
        };
        dayTodayIterable = addDays(dayTodayIterable, 1);
        arrayDay.push(object);
      }

      setWeekInfo({
        ...weekInfo,
        firstDay: nDayToday + 1,
        today: today,
        tomorrowNineAm: tomorrowNineAm,
        arrayDays: arrayDay,
      });
    } else {
      console.log("se puede pedir para hoy y para el resto de la semana");

      //<------------------- son menos de las 9 am o sea que se puede pedir para hoy y para el resto de la semana.----------->

      dayTodayIterable = monday;
      for (let i = 0; i < nDayToday - 1; i++) {
        // estos días se mostrarán pero no se podrán seleccionar y no se calculará el subtotal con ellos.
        let object = {
          day: dayTodayIterable,
          status: false,
        };
        arrayDay.push(object);
        dayTodayIterable = addDays(dayTodayIterable, 1);
      }

      for (let i = nDayToday; i < 6; i++) {
        // estos son los días que se tienen en cuenta para mostrarlos en pantalla y calcular el subtotal
        let object = {
          day: dayTodayIterable,
          status: true,
        };
        arrayDay.push(object);
        dayTodayIterable = addDays(dayTodayIterable, 1);
      }

      setWeekInfo({
        ...weekInfo,
        firstDay: nDayToday,
        today: today,
        tomorrowNineAm: tomorrowNineAm,
        arrayDays: arrayDay,
      });
    }
  }

  async function deleteOrder(idOrder) {
    const response = await axios.delete(`${url}/order/${idOrder}`);
    userInfo();
  }

  async function createOrder(
    idCart,
    idPropertiesOrder,
    childId,
    typeOrder,
    daySelected,
    price
  ) {
    const response = await axios.post(`${url}/order`, {
      id_cart: idCart,
      id_properties_order: idPropertiesOrder,
      id_Child: childId,
      pricepaid: price,
      type: "22222",
      way_to_pay: "null",
      status: "null",
      payed: false,
      date: daySelected,
    });
    userInfo();
  }

  async function updateOrder(
    orderId,
    typeOrder,
    daySelected,
    idPropertiesOrder,
    price
  ) {
    const response = await axios.put(`${url}/order/${orderId}`, {
      type: "1111",
      pricepaid: price,
      way_to_pay: "null",
      status: "null",
      payed: false,
      propertiesOrderId: idPropertiesOrder,
      date: daySelected,
    });
    userInfo();
  }

  if (loading || isHidden) return <Loading />;

  let propertiesOrderWeek2 = [];
  propertiesOrderWeek2 = [propertiesOrderWeek];
  let arrayActives = [];

  if (propertiesOrderWeek2 && propertiesOrderWeek2.length > 0) {
    arrayActives = propertiesOrderWeek2.flatMap((obj) =>
      obj.children?.flatMap((child) =>
        child.carts.flatMap((cart) =>
          cart.orders.filter((order) => order.status === "activo")
        )
      )
    );
  }
  //if(!loading && user && arrayActives.length===0) Navigate("/weeklymenuorder")

  return (
    <LayoutLogged>
      <MainContainer paddingX={4} background={true}>
        <PrevBtn
          text={<span className="small">Volver a editar el pedido</span>}
          onClick={() => Navigate("/weeklymenuorder")}
          style={{ paddingLeft: 0 }}
        />
        <WeekSummary />
        <NextBtn
          text="Confirmar pedido semanal"
          onClick={() => Navigate("/payment")}
        />
      </MainContainer>
    </LayoutLogged>
  );
}
