import Lottie from "lottie-react";
import loadingAnimation from '../../lotties/loading-animation.json';

export const LoadingData = () => {
	return (
		<div className="d-flex flex-column align-items-center my-4">
			<h4 className="fw-bold text-center mt-3">
				Cargando datos...
			</h4>
			<Lottie animationData={loadingAnimation} loop={true} />
		</div>
	)
}