import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ModalMenu from "./ModalMenu";
import { useEffect, useState } from "react";
import { useMenus } from "../../functions/hooks";
import { LoadingMenus } from "../Loading/LoadingMenus";

const MenuCarousel = () => {
  const [modalShow, setModalShow] = useState({
    show: false,
    img: null
  })
  const [menus, ] = useMenus();

  const setModal = (img) => {
    setModalShow({
      show: true,
      img: img
    })
  }

  const renderIndicators = (onClickHandler, isSelected, index, label) => {
    const defStyle = { color: "var(--primary)", cursor: "pointer", width: 14 };
    return (
      <span
        style={{marginRight: 5}}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
      >
        {isSelected ? <Brightness1Icon sx={{ ...defStyle }} /> : <RadioButtonUncheckedIcon sx={{ ...defStyle }} />}
      </span>
    );
  }

  useEffect(() => {console.log(menus);}, [menus])

  while(menus.menuJardin === '' || menus.menuPrimaria === '') { return <LoadingMenus /> }

  return (
    <>
      <Carousel showThumbs={false} renderIndicator={renderIndicators} renderArrowPrev={() => false} renderArrowNext={() => false}>
        <div className="d-flex justify-content-center" onClick={() => setModal(menus.menuJardin)}>
          <img src={menus.menuJardin}  />
          {/* <p className="legend">Menú Primaria</p> */}
        </div>
        <div className="d-flex justify-content-center" onClick={() => setModal(menus.menuPrimaria)}>
          <img src={menus.menuPrimaria}  />
          {/* <p className="legend">Menú Pre-escolar</p> */}
        </div>
      </Carousel>
      <ModalMenu show={modalShow.show} img={modalShow.img} onHide={()=> setModalShow({...modalShow, show: false})} />
    </>
    
  )
}

export default MenuCarousel;