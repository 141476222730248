import axios from "axios";
import url from "../functions/url";
import {usuarioA} from "../context/AuthContext";
import { useEffect, useState } from "react";



// export const useAuth = () => {
//   const context = useContext(authContext);
//   if (!context) throw new Error("There is no Auth provider");
//   return context;
// };

export function getAllOfUser(mail) {
  return async function (dispatch) {
    try {
      let result = await axios.get(`${url}/users/allofuser/${mail}`);
      console.log("action", result.data.children);
      return dispatch({
        type: "GET_ALL_OF_USER",
        payload: result.data,
      });
    } catch (err) {
      console.log("error al traer los datos del usuario");
    }
  };
}

export function userGetAll(mail) {
  return async function (dispatch) {
    try {
      let result = await axios.get(`${url}/users/all/${mail}`);
      return dispatch({
        type: "GET_USER_ALL",
        payload: result.data[0],
      });
    } catch (err) {
      console.log("error al traer los datos del usuario");
    }
  };
}

export function updatePriceVianda(price, id) {
  return async function (dispatch) {
    try {
      const result = await axios.put(`${url}/admin/price/${id}`, {
        price: price,
      });
      return dispatch({
        type: "UPDATE_PRICE_VIANDA",
        payload: price,
      });
    } catch (err) {
      alert("Error al actualizar el precio de la vianda");
    }
  };
}

export function updatePriceMenu(price, id) {
  return async function (dispatch) {
    try {
      const result = await axios.put(`${url}/admin/price/${id}`, {
        price: price,
      });
      return dispatch({
        type: "UPDATE_PRICE_MENU",
        payload: price,
      });
    } catch (err) {
      alert("Error al actualizar el precio del menú");
    }
  };
}

export function getUserAdmin() {
    console.log("usuario en action----------------", usuarioA)
  return async function (dispatch) {
      return dispatch({
        type: "USER_ADMIN",
        payload: usuarioA,
      });
  };
}

export function setLoader(loader) {
return async function (dispatch) {
    return dispatch({
      type: "SET_LOADER",
      payload: loader,
    });
};
}
