import logo from '../../assets/img/icons/logo.svg'

const Logo = ({isOpen, isAdmin, className}) => {
  const classes = `d-flex align-items-center ${className}`
  
  return (
    <div className={classes}>
      <img src={logo} width={47} />
      {!isOpen && !isAdmin ? <span className="gray fw-light smaller ms-3">Colegio Español de Rosario</span> : null }
      {!isOpen && isAdmin && <span className='fw-bold ms-3'>¡Hola, Admin!</span>}
    </div>
  )
}

export default Logo