const Footer = () => {
  const footerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'var(--font-color)', 
    height: 40,
    position: 'relative',
    width: '100%',
    bottom: 0,
  }

  return (
    <footer style={footerStyle}>
      <div className="text-white" style={{fontSize: 12}}> 
        <span className="fw-light">Desarrollado por</span> 
        &nbsp;
        <a href="https://axyoma.com.ar/" target="_blank" className="fw-medium text-white text-decoration-none">AxyomaGlobal</a>
      </div>
    </footer>
  )
}

export default Footer;