import React from 'react'
import PrevBtnOutline from './PrevBtn'
import { Navigate, useNavigate } from 'react-router-dom'

const HomeBtn = () => {
  const navigate = useNavigate();

  return (
    <PrevBtnOutline 
      text={<span className={'smaller'}>Volver al inicio</span>} 
      onClick={() => navigate('/weeklyMenuOrder')} 
      style={{paddingLeft: 0}}
    />
  )
}

export default HomeBtn