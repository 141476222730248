import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import DateAddOrder from "../DateAddOrder/DateAddOrder";
import { connectStorageEmulator } from "firebase/storage";
import Input from "../Input/Input";
import MySelect from "../Input/Select";
import CardShadow from "../Cards/CardShadow";
const {
  isBefore,
  isAfter,
  setHours,
  setMinutes,
  startOfMinute,
  getHours,
  addDays,
  today,
} = require("date-fns");
const { compareAsc, startOfToday, startOfDay, parseISO } = require("date-fns");


export default function AddOrder({
  key,
  numero,
  priceMenu,
  priceVianda,
  propertiesOrder,
  child,
  weekInfo,
  deleteOrder,
  createOrder,
  updateOrder,
  countTotalFunction,
  today,
  childrenInfoOrders,
  selectedDate,
  setSelectedDate,
}) {  


  // si es antes de las 9 am de hoy, se puede  hacer pedido.
  // sino a partir de mañana
  // debo obtener el numero del dia de hoy y si es 0, debe ser a partir de mañana, sino debo ver que hora es.
  // si es antes de las 9am, puedo ver a partir de hoy, sino a partir de mañana y siempre hasta el dia 5(viernes)

  let [contMenu, setContMenu] = useState(0);
  let [contVianda, setContVianda] = useState(0);
  let [cartChild, setCartChild] = useState({});
  let [updateSubTotal, setUpdateSubTotal]=useState(false)

  let [input, setInput] = useState({
    type: "",
  });

  

/*   useEffect(() => {
    setInput((prevInput) => ({
      ...prevInput,
      type: selectedDate,
    }));
  }, [selectedDate]); */

  useEffect(() => {

    var menuOrders = [];
    var viandaOrders = [];
    let arrayFiltered = [];
    arrayFiltered = child.carts?.filter((el) => el.status === "activo");
    
    if (arrayFiltered.length > 0) { 

      const {
        isBefore,
        isAfter,
        setHours,
        setMinutes,
        startOfMinute,
        getHours,
      } = require("date-fns");

      const today = new Date();

      const horarioObjetivo = setMinutes(setHours(startOfMinute(today), 9), 0);
      // se cuenta la fecha de hoy, son mas de las 9am y se calcula la cantidad teniendo en cuenta esto

      viandaOrders = arrayFiltered[0].orders?.filter(
        (el) =>
          el.properties_order.type === "Vianda" &&
          el.status==="activo"

      );
      menuOrders = arrayFiltered[0].orders?.filter(
        (el) =>
          el.properties_order.type.replace(/£/g, "ú") === "Menú semanal" &&
          el.status==="activo"
      );
      // if (isBefore(today, horarioObjetivo)) {


      //   viandaOrders = arrayFiltered[0].orders?.filter(
      //     (el) =>
      //       el.properties_order.type === "Vianda" &&
      //       el.date?.slice(0, 10) >= today.toISOString().slice(0, 10)
      //   );
      //   menuOrders = arrayFiltered[0].orders?.filter(
      //     (el) =>
      //       el.properties_order.type.replace(/£/g, "ú") === "Menú semanal" &&
      //       el.date?.slice(0, 10) >= today.toISOString().slice(0, 10)
      //   );

      // } else {
      //   // NO contar la fecha de hoy, son mas de las 9am y se calcula la cantidad teniendo en cuenta esto
      //   console.log("ejecutad", arrayFiltered)
      //   viandaOrders = arrayFiltered[0].orders?.filter(
      //     (el) =>
      //       el.properties_order.type === "Vianda" &&
      //       el.date?.slice(0, 10) > today.toISOString().slice(0, 10)
      //   );
      //   menuOrders = arrayFiltered[0].orders?.filter(
      //     (el) =>
      //       el.properties_order.type.replace(/£/g, "ú") === "Menú semanal" &&
      //       el.date?.slice(0, 10) > today.toISOString().slice(0, 10)
      //   );
      // }

      setContMenu(menuOrders?.length);
      setContVianda(viandaOrders?.length);
      countTotalFunction(menuOrders?.length, viandaOrders?.length);
    }
  }, [child]);

  
  function fUpdateSubTotal(){
    setUpdateSubTotal(!updateSubTotal)
  }
  return (
    <div>
      {child ? (
        <div className="mt-4">
          <div className="mb-3">
            <MySelect
              name="id_Child"
              label={"Seleccionar hijo/a"}
              value={child.name}
              options={[child.name]}
              aria-label="Seleccionar hijo/a"
            />
          </div>

          <h6 className="fw-bold text-center px-4 mt-4 mb-3">
            Completá los días que quieras menúes o viandas
          </h6>
          <CardShadow>
            {weekInfo
              ? weekInfo.arrayDays?.map((dia) => {
                  return (
                    <DateAddOrder
                      contMenu={contMenu}
                      setContMenu={setContMenu}
                      contVianda={contVianda}
                      setContVianda={setContVianda}
                      propertiesOrder={propertiesOrder}
                      dia={dia}
                      child={child}
                      deleteOrder={deleteOrder}
                      createOrder={createOrder}
                      updateOrder={updateOrder}
                      childrenInfoOrders={childrenInfoOrders}
                      setUpdateSubTotal={setUpdateSubTotal}
                      updateSubTotal={updateSubTotal}
                      fUpdateSubTotal={fUpdateSubTotal}
                    />
                  );
                })
              : null}
          </CardShadow>
        </div>
      ) : null}
      <div className="d-flex justify-content-end fw-bold gray">
        Subtotal: ${priceMenu * contMenu + priceVianda * contVianda}.-
      </div>
      <hr
        className="my-4"
        style={{ border: "1px solid #2D2D2D", opacity: 0.3 }}
      />
    </div>
  );
}
