import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import ElementAdminDate from "./FileDownload/ElementAdminDate/ElementAdminDate";
import url from "../../functions/url";
import NavbarAdmin from "../Navbar/Admin/NavbarAdmin";
import { LoadingData } from "../Loading/LoadingData"
import MainContainer from "../Container/MainContainer";
import Divider from "../Divider";
import ChangeWeeklyMenu from "./ChangeWeeklyMenu";
import { AdminPagination } from "./Pagination";
import { useWeek } from "../../functions/hooks";
import {getUserAdmin} from "../../actions/index"

export default function Admin() {
  const Navigate = useNavigate();
  const { user, loading } = useAuth();
  const [userData, setUserData] = useState(null);
  const [peticiones, setPeticiones] = useState(false);
  const [allDataUsers, setAllDataUsers] = useState([]);
  const itemsPerPage = 1;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = allDataUsers?.data?.slice(startIndex, endIndex);
  const { weekInit, weekEnd } = useWeek();
  const [dataUsersFiltered, setDataUserFiltered] = useState([]);

  if (!loading && !user) {
    Navigate("/");
  }

  async function getUser() {
    setPeticiones(true);
    const allData = await axios.get(`${url}/admin`);
    setAllDataUsers(allData);
    const userData = await axios.get(`${url}/users/${user?.email}`);
    setUserData(userData);
  }
  useEffect(() => {
    getUserAdmin();
  }, []);


  useEffect(() => {
    getUser();
  }, [user]);



  useEffect(() => { }, [startIndex, currentPage, allDataUsers])

  function dateFiltered(fdate) {
    const arrayDateFiltered= currentPageData?.filter((el) => el.date===fdate)
    setDataUserFiltered(arrayDateFiltered)

  }

  return (
    <>
      <NavbarAdmin user={userData?.data} />
      <MainContainer background={true} paddingX={4} paddingY={3}>
        <h1 className="fw-semibold secondary">Panel Admin</h1>
        <Divider className={"my-3"} />
        <div>
          <ChangeWeeklyMenu />
        </div>
        <Divider className={"my-3"} />
        <div className="d-flex flex-column justify-content-center mt-3">
          <h4 className="fw-semibold secondary mb-1">Listado de pedidos</h4>
          {currentPageData?.length > 0 ? currentPageData?.map((el) => (
            <div>
              <p>
                Semana&nbsp;
                <span id="week-start">{weekInit}</span>
                &nbsp;a&nbsp;
                <span id="week-end">{weekEnd}</span>
              </p>
              <ElementAdminDate
                dateFiltered={dateFiltered}
                date={el.date}
                grades={el.grades}
                dataUsersFiltered={dataUsersFiltered}
              />
            </div>
          ))  : <LoadingData /> }
        </div>
        <AdminPagination
          data={allDataUsers}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
        />
      </MainContainer>
    </>
  );
}
