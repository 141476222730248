import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import bannerImg from '../../assets/img/banner-recoverPassword.svg'
import BannerTop from "../BannerTop";
import Input from "../Input/Input";
import iconMail from '../../assets/img/icons/icon-mail.svg'
import Divider from "../Divider";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NextBtn from "../Buttons/NextBtn";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";


export default function ResetPassword() { 
  const [ show, setShow ] = useState({
    resetForm: true,
    result: false
  })
  const Navigate = useNavigate();
  const [input, setInput] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({
    email: "",
  });

  const { resetPassword } = useAuth();

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...input,
        [e.target.name]: e.target.value,
      })
    );

  }

  const MySwal = withReactContent(Swal)
  const handleResetPassword = async (e) => {
    try {
      await resetPassword(input.email);
      setShow({
        resetForm: false,
        result: true
      })
      MySwal.fire({
        title: <strong>¡E-mail enviado!</strong>,
        icon: 'success'
      })
    } catch (error) {
      console.log(error.message);
      MySwal.fire({
        title: <strong>Ups... hubo un error</strong>,
        html: <p>Intente nuevamente</p>,
        icon: 'error'
      })
    }
  };
  return (
    <div className="container">
      <BannerTop imgSrc={bannerImg} width={'85%'} title={'¡Recuperá tu contraseña!'} />

      <div className="px-3" style={{display: `${show.resetForm ? 'block' : 'none'}`}}>
        <div className="mt-4 mb-1 w-100">
          <Input 
            name="email"
            label="E-mail"
            type='email'
            className="input-nombre-detalles"
            onChange={(e) => handleChange(e)}
            value={input.email}
            isInputFilled={input.email !== ''}
            icon={iconMail}
            error={errors.email}
          />
        </div>
        <div className="mt-3 mb-1 w-100">
          <button
            onClick={(e) => handleResetPassword(e)}
            type="submit"
            disabled={errors.email || !input.email ? true : false}
            className="btn btn-primary mt-2 w-100 py-3"
          >
            Reestablecer contraseña
          </button>
        </div>
      </div>
      
      <div className="mt-4 mb-1 px-3" style={{display: `${show.result ? 'block' : 'none'}`}}>
        <p className="text-center fw-medium gray">El restablecimiento de tu contraseña fue enviado vía e-mail a <br/><span className="fw-bold">{input.email}</span></p>
        <Divider width={2} className='mb-4'/>
        <NextBtn text={'Volver'} type="submit" onClick={() => Navigate("/")} />
      </div>
    </div>
  );
}

function validarCorreo(valor) {
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valor)) {
    return false;
  } else {
    return true;
  }
}

function validate(input) {
  let errors = {};
  if (!input.email) {
    errors.email = "Este campo es requerido";
  } else if (validarCorreo(input.email)) {
    errors.email = "Debe ingresar un email válido";
  }
  return errors;
}
