import React from 'react'

const DaySummary = ({weekDay, date, option, price}) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column small">
          <span>{weekDay} {date}</span>
          <span className="fw-semibold">{option}</span>
        </div>
        <div>
          <span className="fw-bold primary">${price}.-</span>
        </div>
      </div>
      <hr style={{background: 'rgba(45, 45, 45, .05)'}} className="mt-2" />
    </div>
  )
}

export default DaySummary