import { useEffect } from "react";
import { Pagination } from "react-bootstrap";

export const AdminPagination = ({data, currentPage, setCurrentPage, itemsPerPage}) => {
  const totalPages = Math.ceil(data?.data?.length / itemsPerPage)
  
  const items = []
  for(let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item 
				key={number} 
				active={number === currentPage} 
				onClick={()=> handlePageChange(number)}
			>
        {number}
      </Pagination.Item>,
    );
  }

	const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
    window.scrollTo(0,0)
    document.querySelector('body').style.overflowY = 'scroll'
  }

	useEffect(() => {}, [data])

	return (
		<Pagination className="d-flex justify-content-center mt-2">
			{ totalPages > 1 && items}
		</Pagination>
	)
}