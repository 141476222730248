import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import BannerTop from "../BannerTop";
import bannerImg from "../../assets/img/banner-success.svg";
import Divider from "../Divider";
import NextBtn from "../Buttons/NextBtn";
import url from "../../functions/url";

export default function CheckMail() {
  const Navigate = useNavigate();
  const { user, logout } = useAuth();

  if (user && user?.emailVerified) Navigate("/addchildren");
  return (
    <div className="container">
      <BannerTop 
        imgSrc={bannerImg}
        width={"75%"}
        title={"Revisa tu correo electrónico"}
        text={
          <span>
            Aún no has verificado tu cuenta, se ha enviado un correo a
            <br /> a <span className="fw-bold">{user?.email}. </span>
            Por favor revísalo para poder continuar.
          </span>
        }
      />
      <NextBtn text={"Volver a Login"} onClick={async() => {
        await logout()
        Navigate("/")}} />
      <div className="px-3"></div>
    </div>
  );
}
